import React, { useContext, useEffect, useMemo, useState } from "react";
import MySpaceVertical from "../../../components/mySpace/MySpaceVertical";
import { useTranslation } from "react-i18next";
import MyPageHeader from "../../../components/myPageHeader/MyPageHeader";
import MyTable from "../../../components/myTable/MyTable";
import UtilNotify from "../../../utils/UtilNotify";
import UtilDate from "../../../utils/UtilDate";
import MyButtonText from "../../../components/myButton/MyButtonText";
import MySpace from "../../../components/mySpace/MySpace";
import FormApp from "../../../components/formApp/FormApp";
import { Flex } from "antd";
import InputFormRangeDatePicker from "../../../components/inputFormRangeDatePicker/InputFormRangeDatePicker";
import { useForm } from "antd/es/form/Form";
import { ClientContext } from "../../../layouts/clientSettingsLayout/ClientSettingsLayout";
import LogsActions from "../../../actions/LogsActions";

const formName = "formLogFilter";

const ClientLogs = () => {
  const { t } = useTranslation();
  const [form] = useForm();

  const { clientData } = useContext(ClientContext);

  const [totalRows, settotalRows] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setpagination] = useState({ page: 0, perPage: 50 });
  const [sort, setsort] = useState("");
  const [filter, setfilter] = useState({});

  useEffect(() => {
    const fill = async () => {
      setLoading(true);
      const fetchParams = {
        page: pagination?.page,
        perPage: pagination?.perPage,
        sort: sort,
        filter: { ...filter, idClient: clientData?.idClient },
      };
      const result = await LogsActions.fillPagination(fetchParams);
      if (result.success) {
        setData(result.data);
        settotalRows(result.totalRows);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setLoading(false);
    };
    fill();
  }, [clientData?.idClient, sort, pagination, filter]);

  const onChangeTable = (_, __, sorter) => {
    let so = "";
    if (sorter.order) {
      so = `${sorter.columnKey} ${sorter.order === "ascend" ? "ASC" : "DESC"}`;
    }
    setsort(so);
  };

  const columns = useMemo(() => {
    let col = [
      {
        title: t("WORD_N"),
        dataIndex: "idLog",
        key: "idLog",
        width: 80,
        sorter: true,
      },
      {
        title: t("WORD_USER"),
        dataIndex: "fName",
        key: "fName",
        width: 200,
        sorter: true,
      },
      {
        title: t("WORD_TYPE"),
        dataIndex: "type",
        key: "type",
        width: 120,
        sorter: true,
        align: "center",
        render: (value) => {
          const type = LogsActions.getTypeLabel(value);
          return (
            <div
              style={{ minWidth: 70, textAlign: "center", borderRadius: 20 }}
              color={type.color}
            >
              {type.label}
            </div>
          );
        },
      },
      {
        title: t("WORD_IP"),
        dataIndex: "clientIp",
        key: "clientIp",
        width: 200,
        sorter: true,
      },
      {
        title: t("WORD_COUNTRY"),
        dataIndex: "country",
        key: "country",
        width: 200,
        sorter: true,
      },
      {
        title: t("WORD_CREATED_DATE"),
        dataIndex: "createDate",
        key: "createDate",
        width: 200,
        sorter: true,
        render: (value) => {
          return <div>{UtilDate.formatDate(value)}</div>;
        },
      },
    ];
    return col;
  }, [t]);

  const onFinish = (values) => {
    if (values.date) {
      values.dateFrom = values.date[0];
      values.dateTo = values.date[1];
    }
    setfilter(values);
  };

  const handleChangeFilter = () => {
    setpagination((ov) => {
      return { page: 0, perPage: ov.perPage };
    });
    form.submit();
  };

  return (
    <>
      <MySpaceVertical fullWidth size={"middle"}>
        <MyPageHeader
          title={t("WORD_CLIENT_LOGS")}
          inPage
          extraLeft={
            <MySpace>
              <FormApp
                onFinish={onFinish}
                name={formName}
                form={form}
                className="filter-form"
                initialValues={{ dateType: 1 }}
              >
                <Flex gap={5} align="center" justify="center">
                  <InputFormRangeDatePicker
                    name="date"
                    placeholder={t("WORD_DATE")}
                    onChange={handleChangeFilter}
                  />
                  <MyButtonText
                    label={t("WORD_RESET_FILTERS")}
                    onClick={() => {
                      form.resetFields();
                    }}
                  />
                </Flex>
              </FormApp>
            </MySpace>
          }
        />
        <MyTable
          rowKey="idLog"
          columns={columns}
          loading={loading}
          dataSource={data}
          total={totalRows}
          page={pagination?.page}
          pageSize={pagination?.perPage}
          onChange={onChangeTable}
          onChangePage={(e, ee) => {
            setpagination({ page: e, perPage: ee });
          }}
        />
      </MySpaceVertical>
    </>
  );
};

export default ClientLogs;
