import React from "react";
import { Card } from "antd";

const MyCard = ({
  children,
  className,
  style,
  fullHeight,
  onClick,
  onDoubleClick,
  withoutRadius,
  borderRadius,
  loading,
  variant,
}) => {
  return (
    <Card
      style={{
        height: fullHeight ? "100%" : "",
        borderRadius: withoutRadius ? 0 : borderRadius || 20,
        ...style,
      }}
      className={`my-card ${className || ""} `}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      variant={variant}
      loading={loading}
    >
      {children}
    </Card>
  );
};

export default MyCard;
