import React from "react";
import { Input } from "antd";
import "./MyInputForm.scss";

const InputText = (props) => {
  const {
    className = "",
    label,
    placeholder,
    readOnly = false,
    allowClear = true,
    onPressEnter,
    onChange,
    value,
    onClear,
    style,
    prefix,
  } = props;

  return (
    <Input
      className={`my-input ${className || ""}`}
      style={style}
      value={value}
      readOnly={readOnly}
      placeholder={
        (props.hasOwnProperty("placeholder") && placeholder) || label || ""
      }
      label={label}
      autoComplete={"nope"}
      allowClear={allowClear}
      prefix={prefix}
      onChange={(e) => {
        onChange && onChange(e.target.value);
        if (!e.target.value) onClear && onClear();
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onPressEnter && onPressEnter(e.target.value);
        }
      }}
    />
  );
};

export default InputText;
