import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import AdminActions from "../../actions/AdminActions";
import UtilNotify from "../../utils/UtilNotify";
import ApiStorage from "../../redux/ApiStorage";
import MyPageHeader from "../../components/myPageHeader/MyPageHeader";
import FormApp from "../../components/formApp/FormApp";
import InputTextFormItem from "../../components/myInputForm/InputTextFormItem";
import MyDivider from "../../components/myDivider/MyDivider";
import MySpace from "../../components/mySpace/MySpace";
import MyText from "../../components/myText/MyText";
import MyButtonSecondary from "../../components/myButton/MyButtonSecondary";
import useNavigateApi from "../../appRoute/useNavigateApi";
import MyCard from "../../components/myCard/MyCard";
import { Flex } from "antd";
import MyTooltip from "../../components/myTooltip/MyTooltip";
import { InfoCircleOutlined } from "@ant-design/icons";
import InputEmailFormItem from "../../components/myInputForm/InputEmailFormItem";
import MyButtonSuccess from "../../components/myButton/MyButtonSuccess";
// import SelectTheme from "../../components/selects/SelectTheme";
// import SelectLanguage from "../../components/selects/SelectLanguage";

const formName = "form-admin-settings";

const AdminSettingsPage = () => {
  const { t } = useTranslation();
  const navigateApi = useNavigateApi();
  const [form] = useForm();

  const [loading, setloading] = useState(false);
  const [saving, setsaving] = useState(false);

  // const selectedTheme = useSelector((state) => {
  //   return state.selectedTheme;
  // });

  const is2FAenabled = useSelector((state) => {
    return state.currentUser.is2FAenabled;
  });

  const idAdmin = useSelector((state) => {
    return state.currentUser?.idAdmin;
  });

  useEffect(() => {
    const getOne = async () => {
      setloading(true);
      if (idAdmin) {
        const result = await AdminActions.getOne(idAdmin);
        if (result.success) {
          form.setFieldsValue(result.data);
        } else {
          UtilNotify.notifyErrorServer(result.errMsg);
        }
      }
      setloading(false);
    };
    getOne();
  }, [form, idAdmin]);

  const onFinish = async (values) => {
    setsaving(true);
    const result = await AdminActions.saveSettings(idAdmin, values);
    if (result.success) {
      UtilNotify.notifySuccess("WORD_SUCCESS");
      ApiStorage.setCurrentUser(result.data);
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    setsaving(false);
  };

  return (
    <>
      <MyCard fullHeight loading={loading}>
        <MyPageHeader title={t("WORD_ACCOUNT_SETTINGS")} />
        <MyDivider />
        <FormApp form={form} onFinish={onFinish} name={formName}>
          <InputTextFormItem name="fName" label={t("WORD_NAME")} required />
          <InputTextFormItem name="phone" label={t("WORD_PHONE")} />
          <InputEmailFormItem name="email" label={t("WORD_EMAIL")} />
          {/* <SelectLanguage
            name="language"
            label={t("WORD_SELECT_LANGUAGE")}
            tooltipTitle={t("WORD_INTERFACE_LANGUAGE")}
            required
          /> */}
        </FormApp>
        <MyButtonSuccess
          form={formName}
          label={t("WORD_SAVE_CHANGES")}
          loading={saving}
          style={{ marginTop: 20, marginBottom: 10 }}
        />
        <MyDivider />
        {/* <Flex justify="space-between" style={{ marginTop: 25 }} align="center">
          <MySpace block>
            <MyText size={14}>{t("WORD_APPEARANCE")}</MyText>
            <MyTooltip title={t("WORD_THEME_SUBTITLE")}>
              <InfoCircleOutlined />
            </MyTooltip>
          </MySpace>
          <SelectTheme
            width={150}
            onChange={(e) => ApiStorage.setSelectedTheme(e)}
            value={selectedTheme}
          />
        </Flex> */}
        <Flex justify="space-between" style={{ marginTop: 25 }} align="center">
          <MySpace block>
            <MyText size={14}>{t("WORD_TWO_STEP_VERIFICATION")}</MyText>
            <MyTooltip title={t("WORD_2FA_SUBTITLE")}>
              <InfoCircleOutlined />
            </MyTooltip>
          </MySpace>
          {is2FAenabled ? (
            <MyButtonSecondary
              label={t("WORD_TURN_OFF")}
              onClick={() => navigateApi("/admins/turn_off_2_step")}
            />
          ) : (
            <MyButtonSecondary
              label={t("WORD_SET_UP")}
              onClick={() => navigateApi("/admins/set_up_2_step")}
            />
          )}
        </Flex>
      </MyCard>
    </>
  );
};

export default AdminSettingsPage;
