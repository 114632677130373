import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import MyModalForm from "../../../components/myModal/MyModalForm";
import { useTranslation } from "react-i18next";
import { useForm } from "antd/es/form/Form";
import FormApp from "../../../components/formApp/FormApp";
import SelectClient from "../../../components/selects/SelectClient";
import SelectCurrency from "../../../components/selects/SelectCurrency";
import InputFormRangeDatePicker from "../../../components/inputFormRangeDatePicker/InputFormRangeDatePicker";
import { Flex, Form } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import InputNumberFormItem from '../../../components/myInputForm/InputNumberFormItem';
import SelectPackage from "../../../components/selects/SelectPackage";
import MyButtonSecondary from "../../../components/myButton/MyButtonSecondary";
import UtilObject from "../../../utils/UtilObject";
import './InvoiceCreateModal.scss';

const formName = "formInvoiceCreate";

const InvoiceCreateModal = forwardRef((props, ref) => {
  const { loading, icon, extraBtn } = props;
  const { t } = useTranslation();
  const [form] = useForm();
  const refModal = useRef();

  const subTotal = Form.useWatch('subTotal', form);

  const [client, setclient] = useState(null);

  useImperativeHandle(ref, () => ({
    view: () => {
      refModal.current.open();
      form.resetFields()
      setclient(null)
    },
  }));

  const onFinish = (values) => {
    console.log("values :>> ", values);
  };

  return (
    <MyModalForm
      ref={refModal}
      loading={loading}
      icon={icon}
      title={t("WORD_CREATE_INVOICE")}
      labelOk={t("WORD_SAVE_CHANGES")}
      success
      formName={formName}
      extraBtn={extraBtn}
      width={600}
    >
      <Flex
        vertical
        gap={10}
        style={{ marginTop: 50 }}
      >
        <FormApp
          onFinish={onFinish}
          name={formName}
          form={form}
          // initialValues={{ invoiceDitals: [{ quantity: 1 }] }}
          onValuesChange={(_, values) => {
            const totalAmount = values?.invoiceDitals?.reduce((sum, item) => sum + (item.totalAmount || 0), 0);

            const discountAmount = values?.discountPer ? ((totalAmount * values?.discountPer) / 100) : values?.discountAmount;

            const finalTotal = (totalAmount - discountAmount);

            form.setFieldsValue({ subTotal: totalAmount, discountAmount: discountAmount, totalAmount: finalTotal > 0 ? finalTotal : 0 })
          }}
        >
          <SelectClient
            name="idClient"
            label={t("WORD_NAME")}
            onChangeItem={(oo) => {
              setclient(oo)
              form.setFieldsValue({ idCurrency: oo.currency });
            }}
          />
          <Flex
            gap={10}
          >
            <InputFormRangeDatePicker
              name="date"
              label={t("WORD_DATE")}
              required
            />
            <SelectCurrency
              name="idCurrency"
              label={t("WORD_CURRENCY")}
            />
          </Flex>


          <Form.List name="invoiceDitals">
            {(fields, { add, remove }) => (
              <Flex vertical gap={10} style={{
                marginTop: 30,
                marginBottom: 15,
                // height: 200, overflowY: 'auto' 
              }}>
                {fields.map(({ key, name }, index) => (
                  <Flex
                    key={key}
                    align="top"
                    gap={10}
                  >
                    <SelectPackage
                      name={[name, 'idService']}
                      label={index === 0 ? t("WORD_SERVICE_NAME") : ''}
                      placeholder={t("WORD_SERVICE_NAME")}
                      style={{ width: 200 }}
                      required
                    />
                    <InputNumberFormItem
                      name={[name, 'quantity']}
                      label={index === 0 ? t("WORD_QUANTITY") : ''}
                      placeholder={t("WORD_QUANTITY")}
                      required
                    />
                    <InputNumberFormItem
                      name={[name, 'price']}
                      label={index === 0 ? t("WORD_PRICE") : ''}
                      placeholder={t("WORD_PRICE")}
                      required
                    />
                    <InputNumberFormItem
                      name={[name, 'totalAmount']}
                      label={index === 0 ? t("WORD_TOTAL") : ''}
                      placeholder={t("WORD_TOTAL")}
                      required
                    />
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Flex>
                ))}
                <Form.Item>
                  <MyButtonSecondary
                    block
                    label={t("WORD_ADD")}
                    icon={<PlusOutlined />}
                    disabled={UtilObject.isEmpty(client)}
                    onClick={() => {
                      add({
                        idService: client?.serviceType,
                        quantity: 1,
                        price: client?.totalAmount,
                        totalAmount: client?.totalAmount
                      })
                    }}
                  />
                </Form.Item>
              </Flex>
            )}
          </Form.List>

          <Flex
            className="total-section"
            vertical
            align="end"
            style={{ width: 300 }}
          >
            <InputNumberFormItem
              name='subTotal'
              label={t("WORD_TOTAL")}
              layout="horizontal"
            />
            <Flex gap={10}>
              <InputNumberFormItem
                name='discountPer'
                label={t("WORD_DISCOUNT")}
                layout="horizontal"
                placeholder="%"
                max={100}
                // suffix='%'
                onChange={(value) => {
                  if (!value) {
                    form.setFieldsValue({ discountAmount: 0 });
                  }
                }}
              />
              <InputNumberFormItem
                name='discountAmount'
                placeholder={t("WORD_DISCOUNT")}
                layout="horizontal"
                max={subTotal}
                onChange={() => {
                  form.setFieldsValue({ discountPer: 0 });
                }}
              />
            </Flex>
            <InputNumberFormItem
              name='totalAmount'
              label={t("WORD_TOTAL_AMOUNT")}
              layout="horizontal"
              required
            />
          </Flex>

        </FormApp>
        {/* <MyTable
          columns={columns}
          dataSource={data}
          loading={loading}
          noFooter
        /> */}
      </Flex>
    </MyModalForm >
  );
});

export default InvoiceCreateModal;
