import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MySpaceVertical from "../../../components/mySpace/MySpaceVertical";
import { useTranslation } from "react-i18next";
import MyPageHeader from "../../../components/myPageHeader/MyPageHeader";
import MyTable from "../../../components/myTable/MyTable";
import MySpace from "../../../components/mySpace/MySpace";
import ClientBillingPaymentCreateModal from "./ClientBillingPaymentCreateModal";
import UtilDate from "../../../utils/UtilDate";
import InvoiceActions from "../../../actions/InvoiceActions";
import UtilNotify from "../../../utils/UtilNotify";
import FormApp from "../../../components/formApp/FormApp";
import { Flex, Tag } from "antd";
import SelectInvoiceDateRangeSelector from "../../../components/selects/SelectInvoiceDateRangeSelector";
import InputFormRangeDatePicker from "../../../components/inputFormRangeDatePicker/InputFormRangeDatePicker";
import InputNumberFormItem from "../../../components/myInputForm/InputNumberFormItem";
import SelectInvoiceStatus from "../../../components/selects/SelectInvoiceStatus";
import MyButtonText from "../../../components/myButton/MyButtonText";
import { useForm } from "antd/es/form/Form";
import UtilNumber from "../../../utils/UtilNumber";
import { ClientContext } from "../../../layouts/clientSettingsLayout/ClientSettingsLayout";
import MyButtonSuccess from "../../../components/myButton/MyButtonSuccess";

const formName = "formBillingFilter";

const ClientBilling = () => {
  const { t } = useTranslation();
  const refModal = useRef();
  const [form] = useForm();

  const { clientData } = useContext(ClientContext);

  const [totalRows, settotalRows] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setpagination] = useState({ page: 0, perPage: 50 });
  const [sort, setsort] = useState("");
  const [filter, setfilter] = useState({});

  const fillFS = useCallback(async () => {
    setLoading(true);
    const fetchParams = {
      page: pagination?.page,
      perPage: pagination?.perPage,
      sort: sort,
      q: "",
      filter: { ...filter, idClient: clientData?.idClient },
      search: "",
    };
    const result = await InvoiceActions.fillPagination(fetchParams);
    if (result.success) {
      setData(result.data);
      settotalRows(result.totalRows);
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    setLoading(false);
  }, [sort, pagination, clientData?.idClient, filter]);

  useEffect(() => {
    fillFS();
  }, [fillFS]);

  const onChangeTable = (_, __, sorter) => {
    let so = "";
    if (sorter.order) {
      so = `${sorter.columnKey} ${sorter.order === "ascend" ? "ASC" : "DESC"}`;
    }
    setsort(so);
  };

  const columns = useMemo(() => {
    let col = [
      {
        title: t("WORD_ID"),
        dataIndex: "idInvoice",
        key: "idInvoice",
        sorter: true,
        width: 50,
      },
      {
        title: t("WORD_CREATED_DATE"),
        dataIndex: "createDate",
        key: "createDate",
        sorter: true,
        align: "center",
        width: 120,
        render: (value) => {
          return <div>{UtilDate.formatDateTime(value)}</div>;
        },
      },
      {
        title: t("WORD_TOTAL_AMOUNT"),
        dataIndex: "totalAmount",
        key: "totalAmount",
        width: 120,
        sorter: true,
        align: "right",
        render(value, { currencyCode }) {
          return (
            <>
              <div>
                {UtilNumber.formatDouble(value)} {currencyCode}
              </div>
            </>
          );
        },
      },
      {
        title: t("WORD_STATUS"),
        dataIndex: "status",
        key: "status",
        sorter: true,
        align: "center",
        width: 100,
        render: (value) => {
          const status = InvoiceActions.getStatus(value);
          return (
            <Tag
              style={{ minWidth: 100, textAlign: "center", borderRadius: 20 }}
              color={status.color}
            >
              {status.label}
            </Tag>
          );
        },
      },
      {
        title: t("WORD_TYPE"),
        dataIndex: "invoiceType",
        sorter: true,
        key: "invoiceType",
        align: "center",
        width: 100,
        render: (value) => {
          const status = InvoiceActions.getType(value);
          return (
            <div
              style={{ minWidth: 100, textAlign: "center" }}
              // color={status.color}
            >
              {status.label}
            </div>
          );
        },
      },
      {
        title: t("WORD_METHOD"),
        dataIndex: "paymentMethod",
        key: "paymentMethod",
        align: "center",
        sorter: true,
        width: 80,
        render: (value) => {
          const status = InvoiceActions.getMethod(value);
          return (
            <div
              style={{ minWidth: 100, textAlign: "center" }}
              // color={status.color}
            >
              {status.label}
            </div>
          );
        },
      },
      {
        title: t("WORD_PERIOD"),
        dataIndex: "invoiceDateFrom",
        key: "invoiceDateFrom",
        align: "center",
        width: 100,
        render: (value, { invoiceDateTo }) => {
          return (
            <div>
              {UtilDate.formatDate(value)}-{UtilDate.formatDate(invoiceDateTo)}
            </div>
          );
        },
      },
      {
        title: t("WORD_ACTIONS"),
        dataIndex: "",
        key: "x",
        width: 50,
        // render: (_, obj) => (
        //   <MySpace>
        //     <MyTooltip title={t("WORD_VIEW")}>
        //       <MyButtonTableAction
        //         icon={<FontAwesomeIcon icon={faEye} color="#fff" size="xs" />}
        //       />
        //     </MyTooltip>
        //   </MySpace>
        // ),
      },
    ];
    return col;
  }, [t]);

  const onFinish = (values) => {
    if (values.date) {
      values.dateFrom = values.date[0];
      values.dateTo = values.date[1];
    }
    setfilter(values);
  };

  const handleChangeFilter = () => {
    setpagination((ov) => {
      return { page: 0, perPage: ov.perPage };
    });
    form.submit();
  };

  return (
    <>
      <MySpaceVertical fullWidth size={"middle"}>
        <MyPageHeader
          extraRight={
            <MySpace>
              <MyButtonSuccess
                label={t("WORD_ADD_PAYMENT")}
                withIcon
                onClick={() => {
                  refModal?.current?.view();
                }}
              />
            </MySpace>
          }
          extraLeft={
            <MySpace>
              <FormApp
                onFinish={onFinish}
                name={formName}
                form={form}
                className="filter-form"
                initialValues={{ dateType: 1 }}
              >
                <Flex gap={5} align="center" justify="center">
                  <SelectInvoiceDateRangeSelector name="dateType" />
                  <InputFormRangeDatePicker
                    name="date"
                    placeholder={t("WORD_DATE")}
                    onChange={handleChangeFilter}
                  />
                  <InputNumberFormItem
                    style={{ minWidth: 110 }}
                    name="totalAmount"
                    placeholder={t("WORD_TOTAL_AMOUNT")}
                    onPressEnter={handleChangeFilter}
                  />
                  <SelectInvoiceStatus
                    name="status"
                    placeholder={t("WORD_SELECT_STATUS")}
                    width={150}
                    onChange={handleChangeFilter}
                  />
                  <MyButtonText
                    label={t("WORD_RESET_FILTERS")}
                    onClick={() => {
                      // ApiStorage.setClientBillingFilters({});
                      setfilter({});
                      form.resetFields();
                    }}
                  />
                </Flex>
              </FormApp>
            </MySpace>
          }
          title={t("WORD_CLIENT_BILLING")}
          inPage
        />
        <MyTable
          rowKey="idInvoice"
          columns={columns}
          loading={loading}
          dataSource={data}
          total={totalRows}
          page={pagination?.page}
          pageSize={pagination?.perPage}
          onChange={onChangeTable}
          onChangePage={(e, ee) => {
            setpagination({ page: e, perPage: ee });
          }}
        />
      </MySpaceVertical>
      <ClientBillingPaymentCreateModal ref={refModal} onChange={fillFS} />
    </>
  );
};

export default ClientBilling;
