import React from "react";
import MyCard from "../myCard/MyCard";
import MySpace from "../mySpace/MySpace";
import MyStatistic from "../myStatistic/MyStatistic";
import UtilNumber from "../../utils/UtilNumber";
import MyTextSecondary from "../myText/MyTextSecondary";
import { useTranslation } from "react-i18next";
import MyTextTitle from "../myText/MyTextTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./StatisticCard.scss";

const StatisticCardBasicColored = ({
  title,
  amount,
  loading,
  text,
  icon,
  color,
  currency,
}) => {
  const { t } = useTranslation();

  let textColor = color ? "#fff" : "#000";

  return (
    <MyCard
      className="stat-card-wrapper-colored"
      style={{ backgroundColor: color }}
      loading={loading}
    >
      <MySpace size="middle" align="center" spaceBetween fullWidth>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {color ? (
            <MyTextSecondary bold color={textColor} size={13}>
              {t(title)}
            </MyTextSecondary>
          ) : (
            <MyTextSecondary bold color="#979798" size={13}>
              {t(title)}
            </MyTextSecondary>
          )}
          {text ? (
            <MyTextTitle color={textColor}>{text}</MyTextTitle>
          ) : (
            <MyStatistic
              style={{
                fontWeight: "bold",
                color: textColor,
              }}
              value={UtilNumber.formatDouble(amount)}
              className="card-stat"
            />
          )}
        </div>
        <div>
          <span className="card-currency-text-bold">{currency}</span>
          {icon ? (
            <FontAwesomeIcon icon={icon} fontSize={30} color={textColor} />
          ) : null}
        </div>
      </MySpace>
    </MyCard>
  );
};

export default StatisticCardBasicColored;
