import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import useNavigateApi from "../../appRoute/useNavigateApi";
import { Menu } from "antd";

const ClientSettingsMenu = () => {
  const { _id } = useParams();
  const { t } = useTranslation();
  const navigateApi = useNavigateApi();
  const location = useLocation();

  const [selectedMenuKey, setselectedMenuKey] = useState(location.pathname);

  const lang = useSelector((state) => {
    return state.lang;
  });

  useEffect(() => {
    setselectedMenuKey(location.pathname);
  }, [location.pathname]);

  const items = [
    {
      label: t("WORD_DASHBOARD"),
      key: `/${lang}/client/view/${_id}`,
      onClick: () => {
        navigateApi(`/client/view/${_id}`);
      },
    },
    {
      label: t("WORD_LOGS"),
      key: `/${lang}/client/logs/${_id}`,
      onClick: () => {
        navigateApi(`/client/logs/${_id}`);
      },
    },
    {
      label: t("WORD_BILLING"),
      key: `/${lang}/client/billing/${_id}`,
      onClick: () => {
        navigateApi(`/client/billing/${_id}`);
      },
    },
    {
      label: t("WORD_USERS"),
      key: `/${lang}/client/users/${_id}`,
      onClick: () => {
        navigateApi(`/client/users/${_id}`);
      },
    },
    {
      label: t("WORD_DEVICES"),
      key: `/${lang}/client/devices/${_id}`,
      onClick: () => {
        navigateApi(`/client/devices/${_id}`);
      },
    },
    {
      label: t("WORD_EDIT"),
      key: `/${lang}/client/edit/${_id}`,
      onClick: () => {
        navigateApi(`/client/edit/${_id}`);
      },
    },
  ];

  return (
    <Menu
      className="second-side-menu"
      mode="vertical"
      defaultSelectedKeys={["1"]}
      items={items}
      selectedKeys={[selectedMenuKey]}
      onSelect={(menu) => {
        setselectedMenuKey(menu?.key);
      }}
    />
  );
};

export default ClientSettingsMenu;
