import { configureStore } from "@reduxjs/toolkit";
import storage from "reduxjs-toolkit-persist/lib/storage";
import { persistStore, persistReducer } from "reduxjs-toolkit-persist";
import AuthReducer from "./AuthReducer";

const persistConfig = {
  key: "rootOrdersbookAdminStorage",
  storage,
};

const persistedReducer = persistReducer(persistConfig, AuthReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
