import { Flex } from "antd";
import React, { useState } from "react";
import MyCard from "../../../components/myCard/MyCard";
import { useTranslation } from "react-i18next";
import MyDivider from "../../../components/myDivider/MyDivider";
import { useForm } from "antd/es/form/Form";
import FormApp from "../../../components/formApp/FormApp";
import MyButtonBlue from "../../../components/myButton/MyButtonBlue";
import InputTextFormItem from "../../../components/myInputForm/InputTextFormItem";
import UtilNotify from "../../../utils/UtilNotify";
import AdminActions from "../../../actions/AdminActions";

const formName = "login_authenticator_form";

const AuthenticatorPage = ({ onSuccess, CaptchaCheck }) => {
  const { t } = useTranslation();
  const [form] = useForm();

  const [saving, setsaving] = useState(false);

  const onFinish = async (values) => {
    setsaving(true)
    const result = await AdminActions.verify2Fa(values);
    if (result.success) {
      onSuccess && onSuccess(result.data);
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
      form.resetFields()
      setsaving(false)
    }
  };

  return (
    <Flex align="center" justify="center" style={{ height: "90vh" }}>
      <MyCard bordered style={{ width: "30%" }}>
        <h5>{t("WORD_LOGIN_TO_ORDERSBOOK_ADMIN_PANEL")}</h5>
        <MyDivider />
        <FormApp form={form} name={formName} onFinish={onFinish}>
          <InputTextFormItem
            label={t("WORD_AUTHENTICATOR_CODE")}
            name="digitCode"
            required
          />
          <Flex align="center" justify="center">
            <MyButtonBlue
              label={t("WORD_LOGIN")}
              form={formName}
              style={{ width: 100 }}
              disabled={!CaptchaCheck}
              loading={saving}
              changePassword
            />
          </Flex>
        </FormApp>
      </MyCard>
    </Flex>
  );
};

export default AuthenticatorPage;
