import React from "react";
import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import UtilNumber from "../../utils/UtilNumber";
import "./MyInputForm.scss";

const InputTextFormItem = (props) => {
  const {
    classNameInput,
    className = "",
    label,
    name,
    placeholder,
    required = false,
    hidden = false,
    readOnly = false,
    allowClear = true,
    onPressEnter,
    hasFeedback = false,
    onChange,
    autoFocus = false,
    style,
    disabled,
    suffix,
    prefix,
    min,
    max,
  } = props;

  const { t } = useTranslation();

  return (
    <Form.Item
      name={name}
      label={label}
      hidden={hidden}
      hasFeedback={hasFeedback}
      className={`app-input ${className || ""}`}
      validateTrigger="onSubmit"
      rules={
        hidden || readOnly
          ? []
          : [
              {
                required: required && !hidden,
                validator: (o, value) => {
                  if (hidden) {
                    return Promise.resolve();
                  }
                  if (required && !value?.trim()) {
                    return Promise.reject(
                      new Error(
                        `${label || placeholder} ${t("WORD_IS_REQUIRED")}`
                      )
                    );
                  }
                  if (max && value.length > max) {
                    return Promise.reject(
                      new Error(`${name} cannot exceed ${max} characters.`)
                    );
                  }
                  if (min && value.length < min) {
                    return Promise.reject(
                      new Error(`${name} cannot exceed ${min} characters.`)
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]
      }
    >
      <Input
        className={`my-input ${classNameInput || ""}`}
        style={style}
        readOnly={readOnly}
        placeholder={
          (props.hasOwnProperty("placeholder") && placeholder) || label
        }
        disabled={disabled}
        autoFocus={autoFocus}
        allowClear={allowClear}
        onChange={(e) => {
          onChange && onChange(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onPressEnter && onPressEnter(e.target.value);
          }
        }}
        suffix={suffix}
        prefix={prefix}
        autoComplete="nope"
        id={UtilNumber.getNewUniqID()}
      />
    </Form.Item>
  );
};

export default InputTextFormItem;
