import React from 'react';
import MySpace from '../mySpace/MySpace';
import MyLink from '../myLink/MyLink';
import MyTextSecondary from '../myText/MyTextSecondary';

const ViewColumn = ({ label, data, isLink, path }) => {
    return (
        <MySpace direction="vertical" style={{ gap: 0 }} fullWidth align="top">
            <MyTextSecondary>
                {label}
            </MyTextSecondary>
            <div className="left-card-label">
                {!isLink ? (
                    <div>{data}</div>
                ) : (
                    <MyLink className="product-link" path={path}>
                        {data}
                    </MyLink>
                )}
            </div>
        </MySpace>

    );
};

export default ViewColumn;