import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelectFormItem from "../mySelectFormItem/MySelectFormItem";

const SelectMonthCount = ({ ...otherProps }) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    const data = [
      { label: t("WORD_1"), value: 1 },
      { label: t("WORD_12"), value: 12 },
      // { label: t("WORD_CUSTOM"), value: 0 },
    ];
    setlist(data);
  }, [t]);

  return <MySelectFormItem {...otherProps} options={list} />;
};

export default SelectMonthCount;
