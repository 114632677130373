import { fetchFromUrlPOSTAsync } from "../utils/UrlHelper";
import UtilIPInfo from '../utils/UtilIPInfo';

const URL_LIST_WITHOUT_PRICE = "service/listWithoutPrice";
const URL_LIST = "service/list";

const fs = {
  fillListWithoutPrice: async () => {
    const result = await fetchFromUrlPOSTAsync(URL_LIST_WITHOUT_PRICE, []);
    return result;
  },
  fillList: async () => {
    const res = await UtilIPInfo.getInfo();
    const result = await fetchFromUrlPOSTAsync(URL_LIST, {
      countryCode: res?.countryCode || 'AM',
    });
    return result;
  },
};

const loc = {
  fillOptionsWithoutPrice: async () => {
    const result = await ServiceActions.fillListWithoutPrice();
    if (result.success) {
      result.data = result.data.map((oo) => {
        return {
          ...oo,
          label: `${oo.serviceName} ${Boolean(oo.useRegister === '1') ? '' : '(Arch.)'}`,
          value: oo.idService,
        }
      })
    }
    return result;
  },
  fillOptions: async () => {
    const result = await ServiceActions.fillList();
    if (result.success) {
      result.data = result.data.map((oo) => {
        return {
          ...oo,
          label: `${oo.serviceName} ${Boolean(oo.useRegister === '1') ? '' : '(Arch.)'}`,
          value: oo.idService,
        }
      })
    }
    return result;
  }
};

const ServiceActions = Object.assign(fs, loc);

export default ServiceActions;
