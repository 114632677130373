import React from "react";
import { Typography } from "antd";

const { Text } = Typography;

const MyTextSecondary = ({
  children,
  size = 14,
  bold,
  style,
  color,
  className,
  strong,
}) => {
  return (
    <Text
      className={className || ""}
      style={{
        fontSize: size,
        fontWeight: bold ? "bold" : "normal",
        color: color || "#A6AAAC",
        ...style,
      }}
      strong={strong || false}
    >
      {children}
    </Text>
  );
};

export default MyTextSecondary;
