import { Layout } from "antd";
import React, { createContext, useEffect, useState } from "react";
import ClientSettingsMenu from "./ClientSettingsMenu";
import ClientActions from '../../actions/ClientActions';
import UtilNotify from "../../utils/UtilNotify";
import { useParams } from "react-router-dom";
import UtilDate from "../../utils/UtilDate";

const { Content } = Layout;

export const ClientContext = createContext();

const ClientSettingsLayout = ({ children, fullPage }) => {
  const [clientData, setClientData] = useState({});
  const [loading, setloading] = useState(false);
  const { _id } = useParams();

  useEffect(() => {
    const fill = async () => {
      setloading(true);
      const resultGetOne = await ClientActions.getOne(_id);
      if (resultGetOne.success) {
        setClientData(Object.assign(resultGetOne.data, {
          activeUntilDate: UtilDate.getDate(resultGetOne.data.activeUntilDate),
          paidUntilDate: resultGetOne.data.paidUntilDate ? UtilDate.getDate(resultGetOne.data.paidUntilDate) : null,
        }));
      } else {
        UtilNotify.notifyErrorServer(resultGetOne.errMsg);
      }
      setloading(false);
    };
    fill();
  }, [_id]);

  const changeClientSettings = (values) => {

    setClientData((ov) => Object.assign({}, ov, values, {
      activeUntilDate: UtilDate.getDate(values.activeUntilDate),
      paidUntilDate: values.paidUntilDate ? UtilDate.getDate(values.paidUntilDate) : null,
    }));
  };

  return (
    <ClientContext.Provider
      value={{ clientData, changeClientSettings, loading }}
    >
      <Layout
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <ClientSettingsMenu />
        <Content
          style={{
            maxWidth: fullPage ? "90%" : 600,
            padding: 10,
          }}
        >
          {children}
        </Content>
      </Layout>
    </ClientContext.Provider>
  );
};

export default ClientSettingsLayout;
