import React, { cloneElement, memo } from "react";
import Icon from "@ant-design/icons";

const MyIcon = ({ icon, onClick, ...otherProps }) => {
  return (
    <Icon
      component={() =>
        cloneElement(icon, {
          ...otherProps,
        })
      }
      onClick={onClick}
    />
  );
};

export default memo(MyIcon);
