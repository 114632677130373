import React, { memo } from "react";
import { useSelector } from "react-redux";
import { useLocation, Navigate } from "react-router-dom";
import UtilObject from "../utils/UtilObject";

const AppRoutePrivate = ({ children }) => {
  const location = useLocation();

  const currentUser = useSelector((state) => {
    return state.currentUser;
  });

  const isEmpty = UtilObject.isEmpty(currentUser);

  if (isEmpty) {
    return (
      <Navigate to="login" state={{ from: location, force: true }} replace />
    );
  }

  // if (currentUser?.is2FAenabled
  //   // && !currentUser?.verify2fasuccess
  // ) {
  //   return (
  //     <Navigate
  //       to="/two_step_verification"
  //       state={{ from: location }}
  //       replace
  //     />
  //   );
  // }

  return children;
};

export default memo(AppRoutePrivate);
