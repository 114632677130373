import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelectFormItem from "../mySelectFormItem/MySelectFormItem";
import ClientActions from "../../actions/ClientActions";

const SelectStatus = ({ allowClear = true, ...otherProps }) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    const data = [
      { label: t("WORD_BLOCKED"), value: ClientActions.STATUS.BLOCKED },
      { label: t("WORD_ACTIVE"), value: ClientActions.STATUS.ACTIVE },
      { label: t("WORD_PENDING"), value: ClientActions.STATUS.PENDING },
      { label: t("WORD_NOT_CONFIRMED"), value: ClientActions.STATUS.NOTCONFIRMED },
      { label: t("WORD_NOT_REGISTERED"), value: ClientActions.STATUS.NOTREGISTERED },
      { label: t("WORD_TRIAL"), value: ClientActions.STATUS.TRIAL },
      { label: t("WORD_DELETED"), value: ClientActions.STATUS.DELETED },
    ];
    setlist(data);
  }, [t]);

  return (
    <MySelectFormItem {...otherProps} options={list} allowClear={allowClear} />
  );
};

export default SelectStatus;
