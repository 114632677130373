import { Space } from "antd";
import React from "react";

const MySpaceCompact = ({
  children,
  direction,
  size = "small",
  align,
  className,
  style,
  fullWidth,
  spaceBetween,
}) => {
  return (
    <Space.Compact
      direction={direction}
      size={size}
      align={align}
      className={className || ""}
      style={{
        width: fullWidth ? "100%" : "",
        justifyContent: spaceBetween ? "space-between" : "",
        ...style,
      }}
    >
      {children}
    </Space.Compact>
  );
};

export default MySpaceCompact;
