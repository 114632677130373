import { useForm } from "antd/es/form/Form";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import UtilNotify from "../../../utils/UtilNotify";
import AdminActions from "../../../actions/AdminActions";
import ApiStorage from "../../../redux/ApiStorage";
import MyPageHeader from "../../../components/myPageHeader/MyPageHeader";
import MyCard from "../../../components/myCard/MyCard";
import FormApp from "../../../components/formApp/FormApp";
import InputTextFormItem from "../../../components/myInputForm/InputTextFormItem";
import MyDivider from "../../../components/myDivider/MyDivider";
import MyCaptcha from "../../../components/myGoogleCaptcha/MyCaptcha";
import useNavigateApi from "../../../appRoute/useNavigateApi";
import MyButtonMain from "../../../components/myButton/MyButtonMain";
import MySpaceVertical from "../../../components/mySpace/MySpaceVertical";

const formName = `verify-up-2fa`;

const Verify2FaPage = ({ isTurnOff }) => {
  const [form] = useForm();
  const navigateApi = useNavigateApi();
  const { t } = useTranslation();

  const [saving, setsaving] = useState(false);

  const [captchaCheck, setcaptchaCheck] = useState(isTurnOff);

  const onFinish = async (values) => {
    setsaving(true);
    let result;
    if (isTurnOff) {
      result = await AdminActions.turnOff2Fa(values);
    } else {
      result = await AdminActions.verify2Fa(values);
    }
    if (result.success) {
      ApiStorage.setCurrentUser(result.data);
      if (isTurnOff) {
        navigateApi("/admins/account_settings");
      } else {
        navigateApi("/home");
      }
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    setsaving(false);
  };

  return (
    <div>
      <MyCard>
        <MySpaceVertical size="middle">
          <MyPageHeader
            onBack={
              isTurnOff
                ? () => {
                    navigateApi(-1);
                  }
                : null
            }
            title={t("WORD_TWO_FACTOR_AUTHENTICATION")}
          />
          <FormApp form={form} onFinish={onFinish} name={formName}>
            <InputTextFormItem
              name="digitCode"
              label={t("WORD_AUTHENTICATION_CODE")}
              placeholder={t("WORD_DIGIT_CODE")}
              required
            />
            <MyDivider />
          </FormApp>
          <MyButtonMain
            form={formName}
            label={isTurnOff ? t("WORD_TURN_OFF") : t("WORD_VERIFY")}
            block
            loading={saving}
            disabled={!captchaCheck}
          />
          <MyDivider />
          <p>
            Open your two-factor authenticator (TOTP) app or browser extension
            to view your authentication code.
          </p>
        </MySpaceVertical>
      </MyCard>
      {isTurnOff ? null : (
        <MyCaptcha
          onChange={(e) => {
            setcaptchaCheck(Boolean(e));
          }}
        />
      )}
    </div>
  );
};

export default Verify2FaPage;
