import { fetchFromUrlPOSTAsync } from "../utils/UrlHelper";

const URL_LIST = "/admin/users/list";
const URL_LOGIN_MAGIC_REQUEST = "/admin/users/loginMagicRequest";

const fs = {
  fillPagination: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_LIST, urlParams);
    return result;
  },
  loginMagicRequest: async (idClient) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_LOGIN_MAGIC_REQUEST + "/" + idClient,
      []
    );
    return result;
  },
};

const loc = {
  STATUS: {
    BLOCKED: 0,
    ACTIVE: 1,
    PENDING: 2,
    NOTCONFIRMED: 3,
    NOTREGISTERED: 4,
    TRIAL: 5,
    DELETED: 6,
  },
  getStatusLabel: (status) => {
    switch (status) {
      case 1:
        return { label: "Active", color: "#92cf5c" };
      case 2:
        return { label: "Pending", color: "#92cf5c" };
      case 3:
        return { label: "Not confirmed", color: "#92cf5c" };
      case 4:
        return { label: "Not registered", color: "#92cf5c" };
      case 5:
        return { label: "Trial", color: "#92cf5c" };
      case 6:
        return { label: "Deleted", color: "#fb6b5b" };
      case 0:
        return { label: "Blocked", color: "#fb6b5b" };
      default:
        return { label: "", color: "" };
    }
  },
  ROLE: {
    SUPERADMIN: 1,
    BRANCH: 2,
    MANAGER: 3,
  },
  getRoleLabel: (role) => {
    switch (role) {
      case 1:
        return { label: "Admin", color: "#5dcff3" };
      case 2:
        return { label: "Branch", color: "#fcc44d" };
      case 3:
        return { label: "Manager", color: "#92cf5c" };
      default:
        return { label: "", color: "" };
    }
  },
};

const UsersActions = Object.assign(fs, loc);

export default UsersActions;
