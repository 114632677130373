import React, { useEffect, useState } from "react";
import MySelectFormItem from "../mySelectFormItem/MySelectFormItem";
import ServiceActions from "../../actions/ServiceActions";
import UtilNotify from "../../utils/UtilNotify";

const SelectPackageFilter = ({ ...otherProps }) => {

  const [list, setlist] = useState([]);

  useEffect(() => {

    const fill = async () => {
      const result = await ServiceActions.fillOptionsWithoutPrice();
      if (result.success) {
        setlist(result.data);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    }
    fill()
  }, []);

  return <MySelectFormItem {...otherProps} options={list} allowClear={true} />;
};

export default SelectPackageFilter;
