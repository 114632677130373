import React, { useContext, useEffect, useMemo, useState } from "react";
import MySpaceVertical from "../../../components/mySpace/MySpaceVertical";
import { useTranslation } from "react-i18next";
import MyPageHeader from "../../../components/myPageHeader/MyPageHeader";
import MyTable from "../../../components/myTable/MyTable";
import UtilNotify from "../../../utils/UtilNotify";
import UtilDate from "../../../utils/UtilDate";
import UsersActions from "../../../actions/UsersActions";
import { ClientContext } from "../../../layouts/clientSettingsLayout/ClientSettingsLayout";
import MySpace from "../../../components/mySpace/MySpace";
import MyButtonText from "../../../components/myButton/MyButtonText";
import SelectStatusMulti from "../../../components/selects/SelectStatusMulti";
import FormApp from "../../../components/formApp/FormApp";
import { useForm } from "antd/es/form/Form";
import { Flex, Tooltip } from "antd";
import SelectRoleMulti from "../../../components/selects/SelectRoleMulti";
import SearchInput from "../../../components/myInputForm/SearchInput";

const formName = "formClientUserFilter";

const ClientUsers = () => {
  const { t } = useTranslation();
  const [form] = useForm();

  const { clientData } = useContext(ClientContext);

  const [totalRows, settotalRows] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [pagination, setpagination] = useState({ page: 0, perPage: 50 });
  const [sort, setsort] = useState("");
  const [filter, setfilter] = useState({});

  useEffect(() => {
    const fill = async () => {
      setLoading(true);
      const fetchParams = {
        page: pagination?.page,
        perPage: pagination?.perPage,
        sort: sort,
        q: "",
        filter: { ...filter, idClient: clientData?.idClient },
        search: search,
      };
      const result = await UsersActions.fillPagination(fetchParams);
      if (result.success) {
        setData(result.data);
        settotalRows(result.totalRows);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setLoading(false);
    };
    fill();
  }, [search, pagination, sort, filter, clientData?.idClient]);

  const columns = useMemo(() => {
    let col = [
      {
        title: t("WORD_ID_USER"),
        dataIndex: "idUser",
        key: "idUser",
        width: 100,
        sorter: true,
      },
      {
        title: t("WORD_USERNAME"),
        dataIndex: "username",
        key: "username",
        width: 200,
        sorter: true,
      },
      {
        title: t("WORD_EMAIL"),
        dataIndex: "email",
        key: "email",
        width: 120,
        sorter: true,
      },
      {
        title: t("WORD_PHONE"),
        dataIndex: "phone",
        key: "phone",
        width: 120,
        sorter: true,
      },
      {
        title: t("WORD_STATUS"),
        dataIndex: "status",
        key: "status",
        width: 100,
        sorter: true,
        render: (value) => {
          const status = UsersActions.getStatusLabel(value);
          return (
            <div
              className="status-wrapper"
              style={{ backgroundColor: status.color }}
            >
              {t(status.label)}
            </div>
          );
        },
      },
      {
        title: t("WORD_ROLE"),
        dataIndex: "role",
        key: "role",
        width: 100,
        sorter: true,
        render: (value) => {
          const status = UsersActions.getRoleLabel(value);
          return (
            <div
              className="status-wrapper"
              style={{ backgroundColor: status.color }}
            >
              {t(status.label)}
            </div>
          );
        },
      },
      {
        title: t("WORD_CREATED_DATE"),
        dataIndex: "createDate",
        key: "createDate",
        width: 100,
        sorter: true,
        render: (value) => {
          return <div>{UtilDate.formatDate(value)}</div>;
        },
      },
    ];
    return col;
  }, [t]);

  const onChangeTable = (_, __, sorter) => {
    let so = "";
    if (sorter.order) {
      so = `${sorter.columnKey} ${sorter.order === "ascend" ? "ASC" : "DESC"}`;
    }
    setsort(so);
  };

  const setpage = (page) => {
    setpagination((ov) => ({ page: page, perPage: ov.perPage }));
  };

  const onFinish = (values) => {
    setfilter(values);
  };

  const handleChangeFilter = () => {
    setpagination((ov) => {
      return { page: 0, perPage: ov.perPage };
    });
    form.submit();
  };

  return (
    <>
      <MySpaceVertical fullWidth size={"middle"}>
        <MyPageHeader
          title={t("WORD_CLIENT_USERS")}
          inPage
          extraLeft={
            <MySpace>
              <Tooltip
                title="IdUsers. username, fName, email"
                trigger={["focus"]}
              >
                <div>
                  <SearchInput
                    placeholder={t("WORD_SEARCH")}
                    onClear={() => {
                      setSearch("");
                      setpage(0);
                    }}
                    onPressEnter={(value) => {
                      setSearch(value);
                      setpage(0);
                    }}
                  />
                </div>
              </Tooltip>
              <FormApp
                onFinish={onFinish}
                name={formName}
                form={form}
                className="filter-form"
              >
                <Flex gap={5} align="center" justify="center">
                  <SelectStatusMulti
                    name="status"
                    placeholder={t("WORD_SELECT_STATUS")}
                    onChange={handleChangeFilter}
                  />
                  <SelectRoleMulti
                    name="role"
                    placeholder={t("WORD_SELECT_ROLE")}
                    onChange={handleChangeFilter}
                  />
                  <MyButtonText
                    label={t("WORD_RESET_FILTERS")}
                    onClick={() => {
                      setfilter({});
                      form.resetFields();
                    }}
                  />
                </Flex>
              </FormApp>
            </MySpace>
          }
        />
        <MyTable
          rowKey="idUser"
          columns={columns}
          loading={loading}
          dataSource={data}
          total={totalRows}
          page={pagination?.page}
          pageSize={pagination?.perPage}
          onChange={onChangeTable}
          onChangePage={(e, ee) => {
            setpagination({ page: e, perPage: ee });
          }}
        />
      </MySpaceVertical>
    </>
  );
};

export default ClientUsers;
