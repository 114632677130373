import React from "react";
import MyDropDown from "../../components/myDropDown/MyDropDown";
import { useTranslation } from "react-i18next";
import MySpace from "../../components/mySpace/MySpace";
import LogoSmallSVG from "../../icons/LogoSmallSVG";
import { Badge } from "antd";
import MyTextTitle from "../../components/myText/MyTextTitle";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./MenuHeader.scss";
import AdminActions from "../../actions/AdminActions";
import ApiStorage from "../../redux/ApiStorage";

const MenuHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const currentUserName = useSelector((state) => {
    return state.currentUser.name;
  });

  const items = [
    {
      label: t("WORD_ACCOUNT_SETTINGS"),
      key: "settings",
      onClick: () => navigate("/en/admins/account_settings"),
    },
    {
      label: t("WORD_LOGOUT"),
      key: "logout",
      onClick: async () => {
        await AdminActions.logOut();
        ApiStorage.clearUser();
        navigate("/en/login");
      },
    },
  ];

  return (
    <MyDropDown className="menu-dropdown" items={items} placement="bottom">
      <MySpace fullWidth size="middle">
        <Badge count={0} style={{ boxShadow: "unset" }}>
          <LogoSmallSVG />
        </Badge>
        <MySpace direction="vertical" fullWidth style={{ gap: 0 }}>
          <MyTextTitle style={{ color: "#fff" }}>
            {currentUserName || "Ordersbook"}
          </MyTextTitle>
          {/* <MyText style={{ color: "#fff" }}>Ordersbook Expo</MyText> */}
        </MySpace>
      </MySpace>
    </MyDropDown>
  );
};

export default MenuHeader;
