import { fetchFromUrlPOSTAsync } from "../utils/UrlHelper";

const URL_LIST = "/admin/device/list";

const fs = {
  fillPagination: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_LIST, urlParams);
    return result;
  },
};

const loc = {
  STATUS: {
    BLOCKED: 0,
    ACTIVE: 1,
  },
  getStatusLabel: (status) => {
    switch (status) {
      case 0:
        return { label: "Blocked", color: "#fb6b5b" };
      case 1:
        return { label: "Active", color: "#92cf5c" };
      default:
        return { label: "", color: "" };
    }
  },
};

const DeviceActions = Object.assign(fs, loc);

export default DeviceActions;
