import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import LoadingIconApp from "./components/loadingIconApp/LoadingIconApp";
import { Spin } from "antd";
import "react-toastify/dist/ReactToastify.css";
import "./index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

const antIcon = (
  <LoadingIconApp style={{ fontSize: 26, color: "#60196b" }} spin />
);

Spin.setDefaultIndicator(<Spin indicator={antIcon} />);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </PersistGate>
  </Provider>
);

reportWebVitals();
