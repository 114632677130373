import { fetchFromUrlPOSTAsync } from "../utils/UrlHelper";
import UtilDate from "../utils/UtilDate";

const URL_LIST = "/admin/client/list";
// const URL_LIST_LOGS = "/admin/client/logs/list";
// const URL_LIST_USERS = "/admin/client/user/list";
const URL_GET_ONE = "/admin/client/getOne";
const URL_UPDATE = "/admin/client/update";
const URL_INSERT_NOTE = "/admin/client/note/insert";
const URL_DELETE_NOTE = "/admin/client/note/delete";

const fs = {
  fillPagination: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_LIST, urlParams);
    return result;
  },
  getOne: async (idClient) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_GET_ONE + "/" + idClient,
      []
    );
    if (result.success) {
      if (result.data.paidUntilDate) {
        result.data.paidUntilDate = UtilDate.getDate(result.data.paidUntilDate);
      }
      if (result.data.activeUntilDate) {
        result.data.activeUntilDate = UtilDate.getDate(
          result.data.activeUntilDate
        );
      }
    }
    return result;
  },
  update: async (values, idClient) => {
    const result = await fetchFromUrlPOSTAsync(URL_UPDATE + "/" + idClient, {
      saveData: values,
    });
    return result;
  },
  insertNote: async (values, idClient) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_INSERT_NOTE + "/" + idClient,
      { saveData: values }
    );
    return result;
  },
  deleteNote: async (idNote) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_DELETE_NOTE + "/" + idNote,
      []
    );
    return result;
  },
};

const loc = {
  STATUS: {
    BLOCKED: 0,
    ACTIVE: 1,
    PENDING: 2,
    NOTCONFIRMED: 3,
    NOTREGISTERED: 4,
    TRIAL: 5,
    DELETED: 6,
  },
  getStatusLabel: (status) => {
    switch (status) {
      case 1:
        return { label: "Active", color: "#92cf5c" };
      case 2:
        return { label: "Pending", color: "#92cf5c" };
      case 3:
        return { label: "Not confirmed", color: "#92cf5c" };
      case 4:
        return { label: "Not registered", color: "#92cf5c" };
      case 5:
        return { label: "Trial", color: "#fcc44d" };
      case 6:
        return { label: "Deleted", color: "#fb6b5b" };
      case 0:
        return { label: "Blocked", color: "#fb6b5b" };
      default:
        return { label: "", color: "" };
    }
  },

  get1CLabel: (status) => {
    switch (status) {
      case 1 || "1":
        return { label: "Yes" };
      default:
        return { label: "No" };
    }
  },
};

const ClientActions = Object.assign(fs, loc);

export default ClientActions;
