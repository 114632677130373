import React from "react";
import MyText from "./MyText";

const MyTextTitle = ({ children, size = 16, style, className, color }) => {
  return (
    <MyText bold size={size} style={style} className={className} color={color}>
      {children}
    </MyText>
  );
};

export default MyTextTitle;
