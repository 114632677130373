import React, { useCallback, useEffect, useState } from "react";
import Login from "./Login";
import AuthenticatorPage from "./AuthenticatorPage";
import ApiStorage from "../../../redux/ApiStorage";
import useNavigateApi from "../../../appRoute/useNavigateApi";
import MyCaptcha from "../../../components/myGoogleCaptcha/MyCaptcha";
import UtilObject from "../../../utils/UtilObject";
import { useLocation } from "react-router-dom";
import ApiLocalStorage from "../../../redux/ApiLocalStorage";
import AdminActions from "../../../actions/AdminActions";
import LoadingIconAppCenter from "../../../components/loadingIconApp/LoadingIconAppCenter";

const LoginPage = () => {
  const location = useLocation();
  const navigateApi = useNavigateApi();

  const [step, setStep] = useState(1);
  const [CaptchaCheck, setCaptchaCheck] = useState(false);
  const [loading, setloading] = useState(true);

  const handleLogin2 = useCallback(
    (dd) => {
      if (dd) {
        ApiStorage.setCurrentUser(dd);
        navigateApi("/dashboard");
      }
    },
    [navigateApi]
  );

  useEffect(() => {
    const checkLogin = async () => {
      if (
        (location && !location.state) ||
        (location.state.hasOwnProperty("force") && !location.state.force)
      ) {
        const obj = ApiLocalStorage.getItem(ApiLocalStorage.TEMP_REMEMBER_ME);
        if (!UtilObject.isEmpty(obj)) {
          let result = await AdminActions.login(obj);
          if (result.success) {
            handleLogin2(result.data);
            return;
          }
        }
      }
      setloading(false);
    };
    checkLogin();
  }, [location, handleLogin2]);

  const handleLogin1 = (dat) => {
    if (dat?.is2FAenabled) {
      ApiStorage.setAccessToken(dat.sessionToken);
      setStep(2);
    } else {
      handleLogin2(dat);
    }
  };

  if (loading) {
    return (
      <div
        style={{ height: "100vh", display: "flex", justifyContent: "center" }}
      >
        <LoadingIconAppCenter />
      </div>
    );
  }

  return (
    <>
      {step === 2 ? (
        <AuthenticatorPage
          onSuccess={handleLogin2}
          CaptchaCheck={CaptchaCheck}
        />
      ) : (
        <Login onSuccess={handleLogin1} CaptchaCheck={CaptchaCheck} />
      )}
      <MyCaptcha
        onChange={() => {
          setCaptchaCheck(true);
        }}
      />
    </>
  );
};

export default LoginPage;
