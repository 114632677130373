import React from "react";
import { Form, Select } from "antd";
import { useTranslation } from "react-i18next";

const MySelectFormMultiItem = ({
  name,
  label,
  options,
  showSearch,
  onSearch,
  loading,
  hidden,
  disabled,
  required,
  placeholder,
  onChange,
  className,
  allowClear,
  style,
  width,
  onPopupScroll,
  onChangeItem,
  errorMessage,
}) => {
  const { t } = useTranslation();

  const errorMessageLabel =
    errorMessage || `${label || placeholder} ${t("WORD_IS_REQUIRED")}`;

  return (
    <Form.Item
      name={name}
      label={label}
      hasFeedback={false}
      hidden={hidden}
      className={`app-select ${className || ""}`}
      rules={[
        { required: required && !hidden, message: errorMessageLabel },
        ({ getFieldValue }) => ({
          validator(rule, value) {
            if (!value) {
              return Promise.resolve();
            }
            if (getFieldValue(name) !== "") {
              return Promise.resolve();
            }
            return Promise.reject(errorMessage);
          },
        }),
      ]}
    >
      <Select
        showSearch={showSearch}
        mode="tags"
        onPopupScroll={onPopupScroll}
        optionFilterProp="children"
        onSearch={onSearch}
        loading={loading}
        onChange={(oo, ooo) => {
          onChange && onChange(oo, ooo);
          onChangeItem && onChangeItem(ooo);
        }}
        disabled={disabled}
        onClear={onSearch}
        allowClear={allowClear}
        style={{ width: width, ...style }}
        placeholder={placeholder}
        options={options}
      ></Select>
    </Form.Item>
  );
};

export default MySelectFormMultiItem;
