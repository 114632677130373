import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import MyModal from "../../../components/myModal/MyModal";
import MySpace from "../../../components/mySpace/MySpace";
import MyLinkA from "../../../components/myLinkA/MyLinkA";
import MyText from "../../../components/myText/MyText";
import MyTable from "../../../components/myTable/MyTable";
import UtilNumber from "../../../utils/UtilNumber";
import { Table } from "antd";
import MyDivider from "../../../components/myDivider/MyDivider";
import paidPNG from "../../../icons/paid.png";
import UtilDate from "../../../utils/UtilDate";
import LogoLightSVG from "../../../icons/LogoLightSVG";
import InvoicePrint from "../invoicePrint/InvoicePrint";
import { useReactToPrint } from "react-to-print";
import UtilNotify from "../../../utils/UtilNotify";
import InvoiceActions from "../../../actions/InvoiceActions";

const InvoiceViewModal = forwardRef((props, ref) => {
  const refModal = useRef();
  const { t } = useTranslation();
  const contentRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [data, setdata] = useState([]);

  useImperativeHandle(ref, () => ({
    viewInvoice: (_id) => {
      fill(_id);
    },
  }));

  const reactToPrintFn = useReactToPrint({ contentRef });

  const fill = useCallback(
    async (idInvoice) => {
      if (idInvoice) {
        setLoading(true);
        const resultGetOne = await InvoiceActions.getOne(idInvoice);
        if (resultGetOne.success) {
          setdata(resultGetOne.data);
        } else {
          UtilNotify.notifyErrorServer(resultGetOne.errMsg);
        }
        setLoading(false);
        refModal.current.open();
      }
    },
    [refModal]
  );

  const columns = useMemo(() => {
    let col = [
      // {
      //   title: t("WORD_N"),
      //   dataIndex: "n",
      //   key: "n",
      //   width: 50,
      //   align: "center",
      //   render: (_, __, index) => <div>{index + 1}</div>,
      // },
      {
        title: t("WORD_DESCRIPTION"),
        dataIndex: "serviceName",
        key: "serviceName",
        render: (value) => <div>{value + " package"}</div>,
        width: 200,
      },
      {
        title: t("WORD_MONTH_CAPITAL"),
        dataIndex: "quantity",
        key: "quantity",
        width: 100,
        align: "center",
      },
      {
        title: t("WORD_PRICE"),
        dataIndex: "price",
        key: "price",
        width: 160,
        render: (value) => <div>{UtilNumber.formatDouble(value)}</div>,
      },
      {
        title: t("WORD_AMOUNT"),
        dataIndex: "totalAmount",
        key: "totalAmount",
        width: 150,
        render: (value) => <div>{UtilNumber.formatDouble(value)}</div>,
      },
    ];
    return col;
  }, [t]);

  return (
    <MyModal
      ref={refModal}
      title={t("WORD_INVOICE")}
      labelOk={t("WORD_PRINT")}
      onOk={reactToPrintFn}
      success
      width={600}
      loading={loading}
    >
      <div style={{ padding: 20 }}>
        <div className="ordersbook-logo-wrapper">
          <LogoLightSVG />
        </div>
        <MySpace fullWidth direction="vertical" size="middle">
          {/* <MyTextTitle size={24} bold style={{ textTransform: "uppercase" }}>
              Ubicross LLC
            </MyTextTitle> */}
          <MySpace fullWidth spaceBetween align="top">
            <MySpace direction="vertical" style={{ gap: 0 }}>
              <MyLinkA
                href="https://www.ordersbook.com/"
                label={"ordersbook.com"}
                blank
                blue
              />
              <MyText>{"Vagharshyan 12 area 1"}</MyText>
              <MyText>{"Yerevan, Armenia"}</MyText>
              <MyText>{"Zip code: 0012"}</MyText>
            </MySpace>
            <MySpace direction="vertical" style={{ gap: 0 }}>
              <MyText>{"Invoice ID: #" + data?.idInvoice}</MyText>
              <MyText>
                {"Date: " + UtilDate.formatDateTime(data?.createDate)}
              </MyText>
              <MyText>
                {"Paying for: " +
                  UtilDate.formatDate(data?.invoiceDateFrom) +
                  " - " +
                  UtilDate.formatDate(data?.invoiceDateTo)}
              </MyText>
              <MyText>
                {`Invoice To: ${data?.legalName || data?.companyName}`}
              </MyText>
            </MySpace>
          </MySpace>
          <MyTable
            rowKey="idInvoice"
            columns={columns}
            dataSource={data?.invoiceItems}
            page={0}
            totalRows={1}
            noFooter
            summary={() => {
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell />
                    <Table.Summary.Cell />
                    <Table.Summary.Cell index={0}>
                      <strong>{t("WORD_TOTAL_AMOUNT")}:</strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>
                      <strong>
                        {UtilNumber.formatDouble(data.totalAmount)}
                      </strong>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
          {data?.status === 1 ? (
            <div>
              <img src={paidPNG} alt="paid" />
            </div>
          ) : null}
        </MySpace>
      </div>
      <MyDivider />
      <div style={{ display: "none" }}>
        <InvoicePrint ref={contentRef} data={data} />
      </div>
    </MyModal>
  );
});

export default InvoiceViewModal;
