import { fetchFromUrlPOSTAsync } from "../utils/UrlHelper";

const URL_LIST = "/admin/logs/list";

const fs = {
  fillPagination: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_LIST, urlParams);
    return result;
  },
};

const loc = {
  TYPE: {
    LOGIN: 1,
    LOGOUT: 2,
    REGISTER: 3,
  },
  getTypeLabel: (status) => {
    switch (status) {
      case 1:
        return { label: "Login", color: "#fb6b5b" };
      case 2:
        return { label: "LogOUt", color: "#92cf5c" };
      case 3:
        return { label: "Register", color: "#92cf8c" };
      default:
        return { label: "", color: "" };
    }
  },
};

const LogsActions = Object.assign(fs, loc);

export default LogsActions;
