import React from "react";
import MySpaceCompact from "../../components/mySpace/MySpaceCompact";
import MenuHeader from "./MenuHeader";
import MyDivider from "../../components/myDivider/MyDivider";
import SideBarMenu from "./SideBarMenu";
import { Layout } from "antd";
import "./MainLayoutSideMenu.scss";

const { Sider } = Layout;

const MainLayoutSideMenu = () => {
  return (
    <Sider width={260} className="main-sider">
      <MySpaceCompact direction="vertical" fullWidth>
        <MenuHeader />
        <MyDivider style={{ borderColor: "rgba(255, 255, 255, 0.1)" }} />
        <SideBarMenu />
      </MySpaceCompact>
    </Sider>
  );
};

export default MainLayoutSideMenu;
