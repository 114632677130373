import { fetchFromUrlPOSTAsync } from "../utils/UrlHelper";

const URL_FILL_PAGINATION = "admin/invoice/list";
const URL_GET_ONE = "admin/invoice/getOne";
const URL_CREATE_QUICK = "admin/invoice/createQuick";
const URL_ADD14DAYS = "admin/invoice/addTrial14Days";

const fs = {
  fillPagination: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_FILL_PAGINATION, urlParams);
    return result;
  },
  getOne: async (idInvoice) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_GET_ONE + "/" + idInvoice,
      []
    );
    return result;
  },
  createQuick: async (values) => {
    const result = await fetchFromUrlPOSTAsync(URL_CREATE_QUICK, {
      saveData: values,
    });
    return result;
  },
  addTrial14Days: async (idClient) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_ADD14DAYS + "/" + idClient,
      []
    );
    return result;
  },
};

const loc = {
  STATUS: {
    SENDED: 1,
    PAID: 2,
    CONFIRMED: 3,
    PENDING: 4,
  },
  TYPE: {
    AUTO: 1,
    CHANGECLIENTSERVICE: 2,
    MANUAL: 3,
  },
  METHOD: {
    INVOICE: 1,
    CREDITCARD: 2,
    PAYPAL: 3,
    ARCA: 4,
    BANKTRANSFER: 5,
    IDRAM: 6,
  },
  getStatus: (status) => {
    switch (status) {
      case 1:
        return { label: "Sent", color: "#ffa500" };
      case 2:
        return { label: "Paid", color: "#92cf8c" };
      case 3:
        return { label: "Confirmed", color: "#92cf5c" };
      case 4:
        return { label: "Pending", color: "#d3d3d3" };
      default:
        return { label: "", color: "" };
    }
  },
  getMethod: (status) => {
    switch (status) {
      case 1:
        return { label: "Invoice", color: "#FFB703" };
      case 2:
        return { label: "Credit Card", color: "#4CAF50" };
      case 3:
        return { label: "Paypal", color: "#0070BA" };
      case 4:
        return { label: "Arca", color: "#673AB7" };
      case 5:
        return { label: "Bank Transfer", color: "#2196F3" };
      case 6:
        return { label: "Idram", color: "#E91E63" };
      default:
        return { label: "", color: "" };
    }
  },
  getType: (status) => {
    switch (status) {
      case 1:
        return { label: "Auto", color: "#4CAF50" };
      case 2:
        return { label: "Change Package", color: "#FF9800" };
      case 3:
        return { label: "Manual", color: "#F44336" };
      default:
        return { label: "", color: "" };
    }
  },
};

const InvoiceActions = Object.assign(fs, loc);

export default InvoiceActions;
