import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CountryActions from "../../actions/CountryActions";
import MySelectFormItem from "../mySelectFormItem/MySelectFormItem";
import UtilNotify from "../../utils/UtilNotify";

const SelectCountry = (props) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    const fill = async () => {
      const result = await CountryActions.fillListOptions();
      if (result.success) {
        setlist(result.data);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    };
    fill();
  }, [t]);

  return <MySelectFormItem {...props} options={list} allowClear={true} />;
};

export default SelectCountry;
