import React from "react";
import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import "./MyInputForm.scss";

const { TextArea } = Input;

const InputTextAreaFormItem = ({
  classNameInput,
  className = "",
  label,
  name,
  placeholder,
  required = false,
  hidden = false,
  readOnly,
  allowClear = true,
  rows = 2,
  onBlur,
  onPressEnter,
  style,
}) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      name={name}
      label={label}
      hidden={hidden}
      className={`app-input-text-area ${className}`}
      rules={[
        {
          required: required && !hidden,
          // message: `${t("WORD_PLEASE_INPUT_YOUR_USERNAME")}`,
          message: `${label || placeholder} ${t("WORD_IS_REQUIRED")}`,
        },
      ]}
    >
      <TextArea
        className={`my-input input-text-area ${classNameInput || ""}`}
        placeholder={placeholder || label}
        autoComplete={"nope"}
        allowClear={allowClear}
        rows={rows}
        readOnly={readOnly}
        onBlur={onBlur}
        // autoSize={autoSize}
        // style={{
        //   border: 'none',
        //   backgroundColor: 'transparent',
        //   boxShadow: 'none',
        //   padding: 0,
        //   resize: 'none',
        //   ...style,
        // }}
        onPressEnter={(e) => {
          onPressEnter && onPressEnter(e.target.value);
        }}
        style={style}
      />
    </Form.Item>
  );
};

export default InputTextAreaFormItem;
