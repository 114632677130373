import { useForm } from "antd/es/form/Form";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MyCard from "../../../components/myCard/MyCard";
import FormApp from "../../../components/formApp/FormApp";
import MyPageHeader from "../../../components/myPageHeader/MyPageHeader";
import MyDivider from "../../../components/myDivider/MyDivider";
import Select1C from "../../../components/selects/Select1C";
import ClientActions from "../../../actions/ClientActions";
import UtilNotify from "../../../utils/UtilNotify";
import InputTextFormItem from "../../../components/myInputForm/InputTextFormItem";
import { ClientContext } from "../../../layouts/clientSettingsLayout/ClientSettingsLayout";
import InputFormDatePicker from "../../../components/inputFormDatePicker/InputFormDatePicker";
import SelectStatusEdit from "../../../components/selects/SelectStatusEdit";
import SelectPackageActive from "../../../components/selects/SelectPackageActive";
import MyButtonSuccess from "../../../components/myButton/MyButtonSuccess";

const formName = "formClientEdit";

const ClientEdit = () => {
  const { t } = useTranslation();
  const [form] = useForm();

  const [saving, setsaving] = useState(false);

  const { clientData, changeClientSettings, loading } =
    useContext(ClientContext);

  const onFinish = async (values) => {
    setsaving(true);
    const result = await ClientActions.update(values, clientData?.idClient);
    if (result.success) {
      changeClientSettings(result.data);
      UtilNotify.notifySuccess(t("WORD_SAVED"));
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    setsaving(false);
  };

  useEffect(() => {
    form?.setFieldsValue(clientData);
  }, [clientData, form]);

  return (
    <>
      <MyCard loading={loading}>
        <MyPageHeader
          title={`${t("WORD_CLIENT_EDIT")} - ${clientData?.companyName}`}
        />
        <MyDivider />
        <FormApp form={form} name={formName} onFinish={onFinish}>
          <SelectPackageActive
            name="serviceType"
            label={t("WORD_PACKAGE")}
            allowClear={false}
            required
          />
          <SelectStatusEdit
            name="status"
            label={t("WORD_STATUS")}
            allowClear={false}
            required
          />
          <InputTextFormItem
            label={t("WORD_APK_VERSION")}
            name="versionApk"
            allowClear={false}
            required
          />
          <InputFormDatePicker
            label={t("WORD_PAID_UNTIL_DATE")}
            name="paidUntilDate"
          />
          <InputFormDatePicker
            label={t("WORD_ACTIVE_UNTIL_DATE")}
            name="activeUntilDate"
            required
          />
          <Select1C name="use1C" label={t("WORD_USE_1C")} required />
          <MyDivider />
          <MyButtonSuccess
            label={t("WORD_SAVE_CHANGES")}
            form={formName}
            loading={saving}
          />
        </FormApp>
      </MyCard>
    </>
  );
};

export default ClientEdit;
