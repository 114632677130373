import { fetchFromUrlPOSTAsync } from "../utils/UrlHelper";

const URL_LOGIN = "admin/login";
const URL_GET_ONE = "admin/getOne";
const URL_SAVE_SETTINGS = "admin/settings/save";
const URL_LOG_OUT = "admin/logout";

const URL_2FA_SIGN_UP = "/admin/2fa/signup";
const URL_2FA_SAVE = "/admin/2fa/save";
const URL_2FA_TURN_OFF = "/admin/2fa/turn_off";
const URL_2FA_VERIFY = "/admin/2fa/verify";

const fs = {
  login: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_LOGIN, urlParams);
    return result;
  },
  getOne: async (_id) => {
    const result = await fetchFromUrlPOSTAsync(URL_GET_ONE + '/' + _id, []);
    return result;
  },
  saveSettings: async (_id, values) => {
    const result = await fetchFromUrlPOSTAsync(URL_SAVE_SETTINGS + '/' + _id, {
      saveData: values,
    });
    return result;
  },
  signUp2Fa: async () => {
    const result = await fetchFromUrlPOSTAsync(URL_2FA_SIGN_UP, {});
    return result;
  },
  turnOff2Fa: async (values) => {
    const result = await fetchFromUrlPOSTAsync(URL_2FA_TURN_OFF, {
      saveData: values,
    });
    return result;
  },
  verify2Fa: async (values) => {
    const result = await fetchFromUrlPOSTAsync(URL_2FA_VERIFY, values);
    return result;
  },
  saveUp2Fa: async (values) => {
    const result = await fetchFromUrlPOSTAsync(URL_2FA_SAVE, {
      saveData: values,
    });
    return result;
  },
  logOut: async () => {
    const result = await fetchFromUrlPOSTAsync(URL_LOG_OUT, []);
    return result;
  },
};

const loc = {};

const AdminActions = Object.assign(fs, loc);

export default AdminActions;
