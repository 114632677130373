import React from "react";
import MyButton from "./MyButton";
import "./MyButton.scss";

const MyButtonTableAction = ({
  className,
  success,
  disabled,
  nextToFilter,
  danger,
  ...otherProps
}) => {
  return (
    <MyButton
      {...otherProps}
      className={`my-button-action ${
        !danger && !success ? "action-gray" : ""
      } ${className} ${success && !disabled ? "my-btn-confirm" : ""} ${
        nextToFilter ? "my-button-action-filter" : ""
      } ${!danger && success && disabled ? "my-btn-confirm-disabled" : ""}
      `}
      type="primary"
      danger={danger}
    />
  );
};

export default MyButtonTableAction;
