import React from "react";
import DashboardStatisticSection from "./DashboardStatisticSection";
import MySpaceVertical from "../../components/mySpace/MySpaceVertical";
import MyPageHeader from "../../components/myPageHeader/MyPageHeader";
import { useTranslation } from "react-i18next";
import ButtonCopy from "../../components/buttonCopy/ButtonCopy";
import MySpace from "../../components/mySpace/MySpace";
import MyText from "../../components/myText/MyText";
import colors from "../../Color.scss";
// import ClientActions from "../../actions/ClientActions";
// import ClientListTable from "../clients/clientList/ClientListTable";

const Dashboard = () => {
  const { t } = useTranslation();

  // const [data, setData] = useState([]);

  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const fill = () => {
  //     setLoading(true);
  //     const fetchParams = {};
  //     const result = ClientActions.fillPagination(fetchParams);
  //     if (result.success) {
  //       setData(result.data);
  //     } else {
  //       UtilNotify.notifyErrorServer(result.errMsg);
  //     }
  //     setLoading(false);
  //   };
  //   fill();
  // }, []);

  // const onSelectChange = (newSelectedRowKeys, selectedRows) => {
  //   setSelectedRowKeys(newSelectedRowKeys);
  // };

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };

  // const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      <MySpaceVertical fullWidth size={"middle"}>
        <MyPageHeader
          title={t("WORD_DASHBOARD")}
          inPage
          extraRight={
            <MySpace className="copy-row-wrapper">
              <MyText color={colors.primaryColorLighter} strong>
                {t("WORD_COPY_APK_LINK")}
              </MyText>
              <ButtonCopy
                className="btn-copy"
                value={
                  "https://ordersbook-assets.fra1.digitaloceanspaces.com/app-Ordersbook.apk"
                }
              />
            </MySpace>
          }
          // extraLeft={
          //   loading ? null : (
          //     <div>
          //       {hasSelected
          //         ? `${t("WORD_SELECTED")} ${selectedRowKeys.length} ${selectedRowKeys.length > 1
          //           ? t("WORD_ITEMS")
          //           : t("WORD_ITEM")
          //         }`
          //         : ""}
          //     </div>
          //   )
          // }
        />
        <DashboardStatisticSection />
        {/* <ClientListTable
          data={data}
          rowSelection={rowSelection}
          loading={loading}
          isDashboard
        /> */}
      </MySpaceVertical>
    </>
  );
};

export default Dashboard;
