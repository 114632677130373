import React from "react";
import MyButton from "./MyButton";
import "./MyButton.scss";

const MyButtonMain = ({
  noForm,
  changePassword,
  success,
  className,
  ...otherProps
}) => {
  return (
    <MyButton
      {...otherProps}
      type="primary"
      htmlType={noForm ? "button" : "submit"}
      className={`${changePassword ? "change-password-btn" : ""} ${
        success ? "my-button-main-success" : ""
      } ${className || ""}`}
    />
  );
};

export default MyButtonMain;
