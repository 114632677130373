import React, { useState } from "react";
import { Flex } from "antd";
import MyCard from "../../../components/myCard/MyCard";
import { useTranslation } from "react-i18next";
import MyDivider from "../../../components/myDivider/MyDivider";
import FormApp from "../../../components/formApp/FormApp";
import { useForm } from "antd/es/form/Form";
import InputTextFormItem from "../../../components/myInputForm/InputTextFormItem";
import InputPasswordFormItem from "../../../components/myInputForm/InputPasswordFormItem";
import MyButtonBlue from "../../../components/myButton/MyButtonBlue";
import AdminActions from "../../../actions/AdminActions";
import UtilNotify from "../../../utils/UtilNotify";
import UtilString from "../../../utils/UtilString";
import CheckBoxFormItem from "../../../components/myCheckbox/CheckBoxFormItem";
import ApiLocalStorage from "../../../redux/ApiLocalStorage";

const formName = "login_form";

const Login = ({ onSuccess, CaptchaCheck }) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [saving, setsaving] = useState(false);

  const onFinish = async (values) => {
    setsaving(true);
    values.password = UtilString.md5(values.password);
    const result = await AdminActions.login(values);
    if (result.success) {
      if (values.rememberMe === true) {
        ApiLocalStorage.setItem(ApiLocalStorage.TEMP_REMEMBER_ME, values);
      }
      onSuccess && onSuccess(result.data);
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    setsaving(false);
  };

  return (
    <Flex align="center" justify="center" style={{ height: "90vh" }}>
      <MyCard style={{ width: "30%" }}>
        <h5>{t("WORD_LOGIN_TO_ORDERSBOOK_ADMIN_PANEL")}</h5>
        <MyDivider />
        <FormApp
          form={form}
          name={formName}
          onFinish={onFinish}
          initialValues={{ rememberMe: true }}
        >
          <InputTextFormItem
            label={t("WORD_USERNAME")}
            name="userName"
            required
          />
          <InputPasswordFormItem
            label={t("WORD_PASSWORD")}
            name="password"
            min={4}
          />
          <CheckBoxFormItem name="rememberMe" label={t("WORD_REMEMBER_ME")} />
          <Flex align="center" justify="center" style={{ marginTop: 20 }}>
            <MyButtonBlue
              label={t("WORD_LOGIN")}
              form={formName}
              style={{ width: 100 }}
              disabled={!CaptchaCheck}
              loading={saving}
              changePassword
            />
          </Flex>
        </FormApp>
      </MyCard>
    </Flex>
  );
};

export default Login;
