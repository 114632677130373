const intState = {
  lang: "en",
  currentUser: {},
  accessToken: "",
  deviceKey: "",
  selectedTheme: "system",
  clientPageFilters: {},
  invoicePageFilters: {},
  affiliatePageFilters: {},
  clientLogsPageFilters: {},
  clientBillingPageFilters: {},
};

const AuthReducer = (state = intState, action) => {
  switch (action.type) {
    case "SET_LANG":
      return { ...state, lang: action.payload };
    case "SET_CURRENT_USER":
      return { ...state, currentUser: action.payload };
    case "SET_ACCESS_TOKEN":
      return { ...state, accessToken: action.payload };
    case "SET_DEVICEKEY":
      return { ...state, deviceKey: action.payload };
    case "SET_SELECTED_THEME":
      return { ...state, selectedTheme: action.payload };
    case "SET_CLIENT_PAGE_FILTERS":
      return { ...state, clientPageFilters: action.payload };
    case "SET_INVOICE_PAGE_FILTERS":
      return { ...state, invoicePageFilters: action.payload };
    case "SET_AFFILIATE_PAGE_FILTERS":
      return { ...state, affiliatePageFilters: action.payload };
    default:
      return state;
  }
};

export default AuthReducer;
