import { Tooltip } from "antd";
import React from "react";
import "./MyTooltip.scss";

const MyTooltip = ({
  children,
  title,
  onClick,
  className,
  placement,
  trigger = "hover",
  ...otherProps
}) => {
  return (
    <Tooltip title={title} trigger={trigger} placement={placement} {...otherProps}>
      <div className={className || ""} onClick={onClick}>
        {children}
      </div>
    </Tooltip>
  );
};

export default MyTooltip;
