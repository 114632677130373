import { ConfigProvider } from "antd";
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import AppRoute from "./appRoute/AppRoute";
import themeLightConfig from "./theme/themeLightConfig";
import { ToastContainer } from "react-toastify";
import "antd/dist/reset.css";
import "./App.scss";

export const history = createBrowserHistory({ basename: "/en" });

function App() {
  return (
    <ConfigProvider theme={themeLightConfig}>
      <BrowserRouter
        history={history}
        future={{
          v7_relativeSplatPath: true,
          v7_startTransition: true,
        }}
      >
        <AppRoute />
        <ToastContainer />
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
