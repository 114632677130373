import React, { useContext, useRef } from "react";
import MySpaceVertical from "../../../components/mySpace/MySpaceVertical";
import MyPageHeader from "../../../components/myPageHeader/MyPageHeader";
import { useTranslation } from "react-i18next";
import { Card, Collapse, Empty, Flex, Popconfirm, Tag } from "antd";
import MyCard from "../../../components/myCard/MyCard";
import MyTextSecondary from "../../../components/myText/MyTextSecondary";
import MySpace from "../../../components/mySpace/MySpace";
import ViewColumn from "../../../components/viewColumn/ViewColumn";
import StatisticCardBasicColored from "../../../components/statisticCard/StatisticCardBasicColored";
import {
  faAlignCenter,
  faCartShopping,
  faCube,
  faMobileAndroid,
  faTags,
  faUsers,
  faStore,
} from "@fortawesome/free-solid-svg-icons";
import MyText from "../../../components/myText/MyText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MyDivider from "../../../components/myDivider/MyDivider";
import InputTextAreaFormItem from "../../../components/myInputForm/InputTextAreaFormItem";
import { ClientContext } from "../../../layouts/clientSettingsLayout/ClientSettingsLayout";
import UtilDate from "../../../utils/UtilDate";
import { CloseCircleOutlined, MessageOutlined } from "@ant-design/icons";
import ClientActions from "../../../actions/ClientActions";
import UtilNotify from "../../../utils/UtilNotify";
import { useForm } from "antd/es/form/Form";
import FormApp from "../../../components/formApp/FormApp";
import ClientBillingPaymentCreateModal from "../clientBilling/ClientBillingPaymentCreateModal";
import UsersActions from "../../../actions/UsersActions";
import { useSelector } from "react-redux";
import MyButtonSuccess from "../../../components/myButton/MyButtonSuccess";

const formName = "formClientNote";

const ClientView = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const refModal = useRef();

  const { clientData, changeClientSettings, loading } =
    useContext(ClientContext);

  const lang = useSelector((state) => {
    return state.lang;
  });

  const statusObj = ClientActions.getStatusLabel(clientData?.status);

  const handleDeleteNote = async (idNote) => {
    const result = await ClientActions.deleteNote(idNote);
    if (result.success) {
      changeClientSettings({
        listNotes: clientData?.listNotes?.filter(
          (on) => on.idClientLog !== idNote
        ),
      });
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
  };

  const onFinish = async (values) => {
    const result = await ClientActions.insertNote(values, clientData?.idClient);
    if (result.success) {
      form.resetFields();
      changeClientSettings({
        listNotes: [...clientData?.listNotes, result.data],
      });
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
  };

  return (
    <>
      <MySpaceVertical fullWidth size={"middle"}>
        <MyPageHeader
          title={t("WORD_CLIENT_VIEW")}
          inPage
          extraRight={
            <MySpace>
              <MyButtonSuccess
                label={t("WORD_ENTERANCE")}
                onClick={async () => {
                  const result = await UsersActions.loginMagicRequest(
                    clientData?.idClient
                  );
                  if (result.success) {
                    const url = `${process.env.REACT_APP_BASE_URL}/${lang}/loginMagic/${result.data.magicToken}`;
                    console.log("url :>> ", url);
                    window.open(url, "_blank", "noopener,noreferrer");
                  }
                }}
                icon={
                  <FontAwesomeIcon
                    icon={faAlignCenter}
                    color="#fff"
                    size="xs"
                  />
                }
              />
              <MyButtonSuccess
                label={t("WORD_ADD_PAYMENT")}
                withIcon
                onClick={() => {
                  refModal?.current?.view();
                }}
              />
            </MySpace>
          }
        />
        <Flex gap={30}>
          <div style={{ width: "45%" }}>
            <MyCard fullHeight loading={loading}>
              <MySpaceVertical fullWidth>
                <MySpace spaceBetween fullWidth align="center">
                  <MyText>{clientData?.companyName}</MyText>
                  <MyTextSecondary>
                    {Math.abs(
                      UtilDate.getDiffDaysCountActive(clientData?.createDate)
                    )}{" "}
                    days ago
                  </MyTextSecondary>
                </MySpace>
                <MySpace spaceBetween fullWidth align="center">
                  <MyTextSecondary>{clientData?.countryName}</MyTextSecondary>
                  <Tag
                    style={{
                      minWidth: 70,
                      textAlign: "center",
                      borderRadius: 20,
                    }}
                    color={statusObj?.color}
                  >
                    {statusObj?.label}
                  </Tag>
                </MySpace>
                <ViewColumn
                  label={t("WORD_REGISTRATION_DATE")}
                  data={UtilDate.formatDate(clientData?.createDate)}
                />
                <ViewColumn
                  label={t("WORD_PHONE")}
                  data={clientData?.companyPhone}
                />
                <ViewColumn
                  label={t("WORD_EMAIL")}
                  data={clientData?.companyEmail}
                />
                <ViewColumn
                  label={t("WORD_BUSINESS_TYPE")}
                  data={clientData?.businessName}
                />
                <ViewColumn
                  label={t("WORD_COMPANY_LEGAL_NAME")}
                  data={clientData?.legalName}
                />
                <ViewColumn
                  label={t("WORD_COMPANY_TIN")}
                  data={clientData?.tin}
                />
                {/* <ViewColumn label={t("WORD_INFO")} data={"???????"} /> */}
              </MySpaceVertical>
              <Collapse
                ghost
                className="note-collapse"
                items={[
                  {
                    key: "1",
                    showArrow: false,
                    label: (
                      <div>
                        <MessageOutlined />{" "}
                        {`${t("WORD_NOTES")} (${
                          clientData?.listNotes?.length
                        })`}
                      </div>
                    ),
                    children: !clientData?.listNotes?.length ? (
                      <Empty
                        description={false}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                      />
                    ) : (
                      <Flex vertical gap={10}>
                        {clientData?.listNotes?.map((oo) => {
                          // return <p style={{ backgroundColor: '#fcf8e3', minHeight: 50, alignItems: 'center', justifyContent: 'center' }}>{oo.description}</p>
                          return (
                            <Card
                              key={oo.idClientLog}
                              type="inner"
                              size="small"
                              title={<p style={{ fontSize: 12 }}>{oo.fName}</p>}
                              style={{
                                width: "100%",
                                backgroundColor: "#fcf8e3",
                              }}
                              extra={
                                <MySpace>
                                  <div style={{ fontSize: 10 }}>{`${Math.abs(
                                    UtilDate.getDiffDaysCountActive(
                                      oo?.createDate
                                    )
                                  )} days ago`}</div>
                                  <Popconfirm
                                    title={t("WORD_ARE_YOU_SURE")}
                                    onConfirm={() =>
                                      handleDeleteNote(oo.idClientLog)
                                    }
                                    okText={t("WORD_YES")}
                                    cancelText={t("WORD_NO")}
                                  >
                                    <CloseCircleOutlined />
                                  </Popconfirm>
                                </MySpace>
                              }
                            >
                              <p style={{ fontSize: 10 }}>{oo.description}</p>
                            </Card>
                          );
                        })}
                      </Flex>
                    ),
                  },
                ]}
              />
              <MyDivider />
              <FormApp form={form} name={formName} onFinish={onFinish}>
                <InputTextAreaFormItem
                  name="description"
                  placeholder={t("WORD_WRITE_A_NOTE")}
                  required
                  onPressEnter={() => {
                    form.submit();
                  }}
                />
              </FormApp>
              {/* <FontAwesomeIcon icon={faSquareArrowUpRight} fontSize={30} color={'red'} /> */}
            </MyCard>
          </div>
          <Flex style={{ width: "55%" }} vertical gap={20}>
            <Flex gap={30}>
              <StatisticCardBasicColored
                title={t("WORD_CURRENT_PLAN")}
                text={clientData?.serviceName}
                icon={faCube}
                loading={loading}
              />
              <StatisticCardBasicColored
                title={t("WORD_PAID_SINCE_START")}
                loading={loading}
                amount={clientData?.sumTotalAmountPaid}
                currency={clientData.currency}
              />
            </Flex>
            <Flex gap={30}>
              <StatisticCardBasicColored
                title={t("WORD_USERS")}
                amount={clientData?.sumCountUsers}
                icon={faUsers}
                color={"#81daf6"}
                loading={loading}
              />
              <StatisticCardBasicColored
                title={t("WORD_CUSTOMERS")}
                amount={clientData?.sumCountCustomers}
                icon={faStore}
                color="#a6d87a"
              />
            </Flex>
            <Flex gap={30}>
              <StatisticCardBasicColored
                title={t("WORD_DEVICES")}
                amount={clientData?.sumCountDevices}
                icon={faMobileAndroid}
                color={"#fcc44d"}
              />
              <StatisticCardBasicColored
                title={t("WORD_PRODUCTS")}
                amount={clientData?.sumCountProducts}
                icon={faTags}
                color={"#FC8D89"}
              />
            </Flex>
            <Flex gap={30}>
              <StatisticCardBasicColored
                title={t("WORD_ORDERS")}
                amount={clientData?.sumCountOrders}
                icon={faCartShopping}
                color={"#b07ff0"}
              />
            </Flex>
          </Flex>
        </Flex>
      </MySpaceVertical>
      <ClientBillingPaymentCreateModal ref={refModal} />
    </>
  );
};

export default ClientView;
