import React, { memo } from "react";
import MyIcon from "./MyIcon";

export const SVGIcon = ({ size }) => {
  return (
    <svg
      className="icon"
      width={size || 24}
      height={size || 24}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.3055 11.1111C20.4074 11.3184 20.4746 11.5404 20.5048 11.7687L21.0616 20.0485L21.3381 24.2102C21.3409 24.6381 21.4081 25.0633 21.5373 25.4721C21.8712 26.2651 22.6743 26.7691 23.5481 26.734L36.8627 25.8631C37.4392 25.8536 37.996 26.0692 38.4105 26.4626C38.7559 26.7903 38.9789 27.2191 39.0491 27.6803L39.0727 27.9604C38.5217 35.5898 32.9183 41.9533 25.3047 43.596C17.6911 45.2387 9.88373 41.7686 6.12142 35.0698C5.03677 33.1236 4.3593 30.9845 4.12876 28.778C4.03246 28.1248 3.99005 27.465 4.00196 26.8051C3.99007 18.6255 9.81494 11.5539 17.9687 9.84913C18.95 9.69633 19.9121 10.2158 20.3055 11.1111Z"
        fill="white"
      ></path>
      <path
        opacity="0.4"
        d="M25.74 4.00164C34.8598 4.23365 42.5246 10.7916 44 19.6246L43.9859 19.6898L43.9457 19.7845L43.9513 20.0447C43.9304 20.3894 43.7973 20.7211 43.5679 20.989C43.329 21.268 43.0026 21.4581 42.6432 21.5318L42.424 21.5619L27.0624 22.5572C26.5515 22.6076 26.0427 22.4428 25.6627 22.1039C25.346 21.8215 25.1436 21.4402 25.0864 21.0294L24.0553 5.69012C24.0374 5.63826 24.0374 5.58203 24.0553 5.53016C24.0694 5.10734 24.2555 4.70768 24.5721 4.42046C24.8887 4.13324 25.3093 3.9824 25.74 4.00164Z"
        fill="white"
      ></path>
    </svg>
  );
};

const DashboardSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(DashboardSVG);
