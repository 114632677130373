import React from "react";
import { Typography } from "antd";

const { Text } = Typography;

const MyText = (props) => {
  const { children, size, bold, className, style, onClick, strong, color } =
    props;

  return (
    <Text
      style={{
        fontSize: size,
        fontWeight: bold ? "bold" : "normal",
        color: color,
        ...style,
      }}
      strong={strong || false}
      className={className}
      onClick={onClick}
      color={color}
    >
      {children}
    </Text>
  );
};

export default MyText;
