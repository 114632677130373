import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelectFormItem from "../mySelectFormItem/MySelectFormItem";

const SelectStatusEdit = ({ allowClear = true, ...otherProps }) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    const data = [
      { label: t("WORD_BLOCKED"), value: 0 },
      { label: t("WORD_ACTIVE"), value: 1 },
      { label: t("WORD_PENDING"), value: 2 },
      { label: t("WORD_TRIAL"), value: 5 },
    ];
    setlist(data);
  }, [t]);

  return (
    <MySelectFormItem {...otherProps} options={list} allowClear={allowClear} />
  );
};

export default SelectStatusEdit;
