import React from "react";

const MyLinkA = ({
  label,
  blank = false,
  href,
  className = "",
  underline,
  style,
  blue,
  bold,
  primaryColor,
  purple,
  footer,
}) => {
  return (
    <div>
      <a
        className={`my-link ${className || ""} ${blue ? "my-link-blue" : ""} ${
          purple ? "my-link-purple" : ""
        } ${primaryColor ? "my-link-primary" : ""} ${
          footer ? "my-link-footer" : ""
        }`}
        href={href}
        target={blank ? "_blank" : ""}
        rel="noopener noreferrer"
        style={{
          textDecoration: underline ? "underline" : "",
          fontWeight: bold ? 600 : 0,
          ...style,
        }}
      >
        {label}
      </a>
    </div>
  );
};

export default MyLinkA;
