import { Modal } from "antd";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import MyText from "../myText/MyText";
import MySpace from "../mySpace/MySpace";
import MyButtonSecondary from "../myButton/MyButtonSecondary";
import LoadingIconApp from "../loadingIconApp/LoadingIconApp";
import MyButtonSuccess from "../myButton/MyButtonSuccess";

const MyModalForm = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const {
    trigerText,
    children,
    width = 450,
    saving,
    destroyOnClose = true,
    title,
    onClose,
    forceRender = false,
    getContainer = false,
    disableOk,
    onVisibleChange,
    // footerExtra,
    loading,
    hideFooter,
    // defaultHeight,
    formName,
    extraBtn,
    labelOk,
  } = props;

  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
    onVisibleChange && onVisibleChange(true);
  };

  const handleCancel = () => {
    setOpen(false);
    onVisibleChange && onVisibleChange(false);
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      showModal();
    },
    close: () => {
      handleCancel();
    },
  }));

  useEffect(() => {
    if (!open) {
      onClose && onClose();
    }
  }, [open, onClose]);

  return (
    <>
      <div className="app-modal-trigger-button" onClick={showModal}>
        {trigerText}
      </div>
      <Modal
        className="my-modal"
        title={<MyText bold>{title}</MyText>}
        open={open}
        confirmLoading={saving}
        onCancel={handleCancel}
        width={width}
        destroyOnClose={destroyOnClose}
        centered
        getContainer={getContainer}
        forceRender={forceRender}
        footer={
          <MySpace fullWidth spaceBetween>
            {hideFooter ? null : (
              <MySpace align="start" style={{ marginTop: 10 }}>
                <MyButtonSuccess
                  label={labelOk || t("WORD_SAVE_CHANGES")}
                  form={formName}
                  loading={saving}
                  disabled={disableOk}
                />
                {extraBtn ? extraBtn : null}
                <MyButtonSecondary
                  key="cancel"
                  onClick={handleCancel}
                  label={t("WORD_CANCEL")}
                  reset
                />
              </MySpace>
            )}
          </MySpace>
        }
      >
        {loading ? <LoadingIconApp /> : children}
      </Modal>
    </>
  );
});

export default MyModalForm;
