import React, { useCallback, useEffect, useMemo, useState } from "react";
import MySpaceVertical from "../../../components/mySpace/MySpaceVertical";
import MyPageHeader from "../../../components/myPageHeader/MyPageHeader";
import { useTranslation } from "react-i18next";
import MySpace from "../../../components/mySpace/MySpace";
import ClientActions from "../../../actions/ClientActions";
import { useSelector } from "react-redux";
import MyTooltip from "../../../components/myTooltip/MyTooltip";
import MyButtonTableAction from "../../../components/myButton/MyButtonTableAction";
import ImportWhiteSVG from "../../../icons/ImportWhiteSVG";
import FormApp from "../../../components/formApp/FormApp";
import SelectPackageFilter from "../../../components/selects/SelectPackageFilter";
import SelectStatus from "../../../components/selects/SelectStatus";
import { useForm } from "antd/es/form/Form";
import { Flex, Tag } from "antd";
import MyButtonText from "../../../components/myButton/MyButtonText";
import UtilNotify from "../../../utils/UtilNotify";
import MyTable from "../../../components/myTable/MyTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignCenter, faEye } from "@fortawesome/free-solid-svg-icons";
import UtilDate from "../../../utils/UtilDate";
import ApiStorage from "../../../redux/ApiStorage";
import SelectCountry from "../../../components/selects/SelectCountry";
import useNavigateApi from "../../../appRoute/useNavigateApi";
import InvoiceActions from "../../../actions/InvoiceActions";
import MyButtonTableActionConfirm from "../../../components/myButton/MyButtonTableActionConfirm";
import UsersActions from "../../../actions/UsersActions";
import Select1C from "../../../components/selects/Select1C";
import SearchInput from "../../../components/myInputForm/SearchInput";

const formName = "formClientFilter";

const ClientList = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const navigateApi = useNavigateApi();

  const [data, setData] = useState([]);
  const [totalRows, settotalRows] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");

  const [pagination, setpagination] = useState({ page: 0, perPage: 50 });
  const [sort, setsort] = useState("");

  const clientPageFilters = useSelector((state) => {
    return state.clientPageFilters;
  });

  const lang = useSelector((state) => {
    return state.lang;
  });

  // useEffect(() => {
  //   form.setFieldsValue(clientPageFilters)
  // }, [clientPageFilters, form])

  useEffect(() => {
    const fill = async () => {
      setLoading(true);
      const fetchParams = {
        page: pagination?.page,
        perPage: pagination?.perPage,
        sort: sort,
        q: clientPageFilters?.searchName || "",
        filter: { ...clientPageFilters },
        search: search,
      };
      const result = await ClientActions.fillPagination(fetchParams);
      if (result.success) {
        setData(result.data);
        settotalRows(result.totalRows);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setLoading(false);
    };
    fill();
  }, [search, sort, pagination, clientPageFilters]);

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onChangeTable = (_, __, sorter) => {
    let so = "";
    if (sorter.order) {
      so = `${sorter.columnKey} ${sorter.order === "ascend" ? "ASC" : "DESC"}`;
    }
    setsort(so);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const onFinish = (values) => {
    setpagination((ov) => {
      return { page: 0, perPage: ov.perPage };
    });
    ApiStorage.setClientPageFilters(values);
  };

  const handleRowClick = (record, e) => {
    if (e.target.closest(".ant-popconfirm") || e.target.closest("button")) {
      return;
    }
    const selectedRowKey = record.IdClients;
    const newSelectedRowKeys = selectedRowKeys.includes(selectedRowKey)
      ? selectedRowKeys.filter((key) => key !== selectedRowKey)
      : [...selectedRowKeys, selectedRowKey];

    onSelectChange(newSelectedRowKeys, []);
  };

  const handleAdd14Days = useCallback(
    async (idClient) => {
      const result = await InvoiceActions.addTrial14Days(idClient);
      if (result.success) {
        setData((ov) => {
          return ov.map((ci) => {
            if (ci.idClient === idClient) Object.assign(ci, result.data);
            return ci;
          });
        });
        UtilNotify.notifySuccess(t("WORD_14_DAYS_ADDED_SUCCESSFULLY"));
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    },
    [t]
  );

  const columns = useMemo(() => {
    const col = [
      {
        title: t("WORD_ID"),
        dataIndex: "idClient",
        key: "idClient",
        width: 60,
        sorter: true,
        align: "center",
      },
      {
        title: t("WORD_COMPANY_NAME"),
        dataIndex: "companyName",
        key: "companyName",
        width: 300,
        sorter: true,
      },
      {
        title: t("WORD_EMAIL"),
        dataIndex: "companyEmail",
        key: "companyEmail",
        width: 200,
      },
      {
        title: t("WORD_PHONE"),
        dataIndex: "companyPhone",
        key: "companyPhone",
        width: 100,
      },
      {
        title: t("WORD_COUNTRY"),
        dataIndex: "countryName",
        key: "countryName",
        width: 100,
        sorter: true,
      },
      {
        title: t("WORD_STATUS"),
        dataIndex: "status",
        key: "status",
        width: 100,
        sorter: true,
        align: "center",
        render: (value) => {
          const status = ClientActions.getStatusLabel(value);
          return (
            <Tag
              style={{ minWidth: 70, textAlign: "center", borderRadius: 20 }}
              color={status.color}
            >
              {status.label}
            </Tag>
          );
        },
      },
      {
        title: t("WORD_PACKAGE"),
        dataIndex: "serviceName",
        key: "serviceName",
        align: "center",
        width: 100,
        sorter: true,
      },
      {
        title: t("WORD_REGISTERED"),
        dataIndex: "createDate",
        key: "createDate",
        align: "center",
        width: 150,
        sorter: true,
        render: (value) => {
          return <div>{UtilDate.formatDate(value)}</div>;
        },
      },
      {
        title: t("WORD_PAID_UNTIL"),
        dataIndex: "paidUntilDate",
        key: "paidUntilDate",
        align: "center",
        width: 130,
        sorter: true,
        render: (value) => {
          return <div>{UtilDate.formatDate(value)}</div>;
        },
      },
      {
        title: t("WORD_ACTIVE_UNTIL"),
        dataIndex: "activeUntilDate",
        key: "activeUntilDate",
        align: "center",
        width: 160,
        sorter: true,
        render: (value) => {
          return <div>{UtilDate.formatDate(value)}</div>;
        },
      },
      {
        title: t("WORD_1C"),
        dataIndex: "useFileManager",
        key: "useFileManager",
        align: "center",
        width: 70,
        sorter: true,
        render(value) {
          const use1c = ClientActions.get1CLabel(value);
          return <div>{t(use1c.label)}</div>;
        },
      },
      {
        title: t("WORD_ACTIONS"),
        dataIndex: "",
        key: "x",
        width: 150,
        render: (_, { idClient, status }) => (
          <MySpace>
            <MyTooltip title={t("WORD_VIEW")}>
              <MyButtonTableAction
                onClick={() => {
                  navigateApi(`/client/view/${idClient}`);
                }}
                icon={<FontAwesomeIcon icon={faEye} color="#fff" size="xs" />}
              />
            </MyTooltip>
            <MyTooltip title={t("WORD_ENTERANCE")}>
              <MyButtonTableAction
                onClick={async () => {
                  const result = await UsersActions.loginMagicRequest(idClient);
                  if (result.success) {
                    const url = `${process.env.REACT_APP_BASE_URL}/${lang}/loginMagic/${result.data.magicToken}`;
                    console.log("url :>> ", url);
                    window.open(url, "_blank", "noopener,noreferrer");
                  }
                }}
                success
                icon={
                  <FontAwesomeIcon
                    icon={faAlignCenter}
                    color="#fff"
                    size="xs"
                  />
                }
              />
            </MyTooltip>
            {status === ClientActions.STATUS.BLOCKED ? (
              <MyTooltip title={t("WORD_ADD_14")}>
                <MyButtonTableActionConfirm
                  title={t("WORD_ARE_YOU_SURE_YOU_WANT_TO_EXTEND")}
                  success
                  label="+14"
                  onConfirm={async () => await handleAdd14Days(idClient)}
                />
              </MyTooltip>
            ) : null}
          </MySpace>
        ),
      },
    ];
    return col;
  }, [t, navigateApi, handleAdd14Days, lang]);

  const handleChangeFilter = () => {
    form.submit();
  };

  return (
    <>
      <MySpaceVertical fullWidth size={"middle"}>
        <MyPageHeader
          title={t("WORD_CLIENTS")}
          inPage
          extraLeft={
            <MySpace>
              <SearchInput
                onClear={() => {
                  setSearch("");
                  // setpage(0);
                }}
                onPressEnter={(value) => {
                  setSearch(value);
                  // setpage(0);
                }}
              />
              <FormApp
                onFinish={onFinish}
                name={formName}
                form={form}
                className="filter-form"
                initialValues={{
                  status: null,
                  idCountry: null,
                  idService: null,
                }}
              >
                <Flex gap={5} align="center" justify="center">
                  <SelectPackageFilter
                    name="idService"
                    placeholder={t("WORD_SELECT_PACKAGE")}
                    width={150}
                    onChange={handleChangeFilter}
                    showSearch
                  />
                  <SelectCountry
                    name="idCountry"
                    placeholder={t("WORD_SELECT_COUNTRIES")}
                    width={170}
                    onChange={handleChangeFilter}
                    showSearch
                  />
                  <SelectStatus
                    name="status"
                    placeholder={t("WORD_SELECT_STATUS")}
                    width={150}
                    onChange={handleChangeFilter}
                    showSearch
                  />
                  <Select1C
                    name="use1C"
                    placeholder={t("WORD_USE_1C")}
                    width={100}
                    onChange={handleChangeFilter}
                  />
                  <MyButtonText
                    label={t("WORD_RESET_FILTERS")}
                    onClick={() => {
                      ApiStorage.setClientPageFilters({});
                      form.resetFields();
                    }}
                  />
                </Flex>
              </FormApp>
              <div>
                {hasSelected
                  ? `${t("WORD_SELECTED")} ${selectedRowKeys.length} ${
                      selectedRowKeys.length > 1
                        ? t("WORD_ITEMS")
                        : t("WORD_ITEM")
                    }`
                  : ""}
              </div>
            </MySpace>
          }
          extraRight={
            <MySpace>
              <MyTooltip
                title={t("WORD_EXPORT_CLIENT")}
                className="link-cursor"
              >
                <MyButtonTableAction nextToFilter icon={<ImportWhiteSVG />} />
              </MyTooltip>
            </MySpace>
          }
        />
        <MyTable
          rowKey="idClient"
          className="client-table"
          columns={columns}
          loading={loading}
          dataSource={data}
          total={totalRows}
          page={pagination?.page}
          pageSize={pagination?.perPage}
          rowSelection={rowSelection}
          onChange={onChangeTable}
          scroll={{ x: 1000 }}
          onRow={(record) => ({
            onClick: (e) => handleRowClick(record, e),
          })}
          onChangePage={(e, ee) => {
            setpagination({ page: e, perPage: ee });
          }}
        />
      </MySpaceVertical>
    </>
  );
};

export default ClientList;
