import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelectFormItem from "../mySelectFormItem/MySelectFormItem";

const Select1C = ({ allowClear = true, ...otherProps }) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    const data = [
      { label: t("WORD_NO"), value: 0 },
      { label: t("WORD_YES"), value: 1 },
    ];
    setlist(data);
  }, [t]);

  return <MySelectFormItem {...otherProps} options={list} allowClear={allowClear} />;
};

export default Select1C;
