import React from "react";
import { Layout } from "antd";

const { Content } = Layout;

const OutsideLayout = ({ children }) => {
  return (
    <Layout
      style={{
        backgroundColor: "#fff",
      }}
    >
      <Content>{children}</Content>
    </Layout>
  );
};

export default OutsideLayout;
