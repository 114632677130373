import React from "react";
import MyCard from "../myCard/MyCard";
import LoadingIconAppCenter from "../loadingIconApp/LoadingIconAppCenter";
import MySpace from "../mySpace/MySpace";
import MyStatistic from "../myStatistic/MyStatistic";
import UtilNumber from "../../utils/UtilNumber";
import MyTextSecondary from "../myText/MyTextSecondary";
import { useTranslation } from "react-i18next";
import "./StatisticCard.scss";

const StatisticCardBasic = ({ title, amount, loading, danger }) => {
  const { t } = useTranslation();

  return (
    <MyCard className="stat-card-wrapper">
      {loading ? (
        <LoadingIconAppCenter />
      ) : (
        <MySpace size="middle">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <MyTextSecondary size={13}>{t(title)}</MyTextSecondary>
            <MyStatistic
              style={{
                fontWeight: "bold",
                color: danger ? "#fa4733" : "",
              }}
              value={UtilNumber.formatDouble(amount)}
              className="card-stat"
            />
          </div>
        </MySpace>
      )}
    </MyCard>
  );
};

export default StatisticCardBasic;
