import React from "react";
import MySpace from "./MySpace";

const MySpaceVertical = ({ children, ...otherProps }) => {
  return (
    <MySpace {...otherProps} direction="vertical">
      {children}
    </MySpace>
  );
};

export default MySpaceVertical;
