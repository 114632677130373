import React from "react";
import { Form, DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import UtilDate from "../../utils/UtilDate";
import "./InputFormRangeDatePicker.scss";

const { RangePicker } = DatePicker;

const InputFormRangeDatePicker = ({
  label,
  name = "DateValue",
  required = false,
  placeholder = "Select Date",
  hidden,
  style,
  readOnly = false,
  allowClear = true,
  onChange,
  hasFeedback = false,
  format = "DD/MM/YYYY",
}) => {
  const { t } = useTranslation();

  // const form = Form.useFormInstance();

  // const [open, setOpen] = useState(false);

  const handleOnChange = (e) => {
    if (!readOnly) {
      if (e) {
        const localDateFrom = e?.[0] ? e[0].startOf("day").format("YYYY-MM-DD") : null;
        const localDateTo = e?.[1] ? e[1].startOf("day").format("YYYY-MM-DD") : null;
        onChange && onChange(e, localDateFrom, localDateTo);
      } else {
        onChange && onChange(null);
      }
    }
    // setOpen(false);
  }

  // const handleThisMonth = () => {
  //   const date = [UtilDate.getThisMonthStart(), UtilDate.getThisMonthEnd()];
  //   form.setFieldsValue({ [name]: date })
  //   handleOnChange(date);
  // }
  // const handleToday = () => {
  //   const date = [UtilDate.getDateToday(), UtilDate.getDateToday()];
  //   form.setFieldsValue({ [name]: date })
  //   handleOnChange(date);
  // }

  const rangePresets = [
    {
      label: 'Today',
      value: [UtilDate.getDateToday(), UtilDate.getDateToday()],
    },
    {
      label: 'This Month',
      value: [UtilDate.getThisMonthStart(), UtilDate.getThisMonthEnd()],
    },
    {
      label: 'Last Month',
      value: [
        UtilDate.getLastMonthStart(),
        UtilDate.getLastMonthEnd(),
      ],
    },
  ];


  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        {
          required: required && !hidden,
          message: `${label || placeholder} ${t("WORD_IS_REQUIRED")}`,
        },
      ]}
      layout="vertical"
      hidden={hidden}
      hasFeedback={hasFeedback}
      style={style}
      className="input-range-picker"
    >
      <RangePicker
        format={format}
        // style={{ width: "100%" }}
        // placeholder={placeholder || label}
        style={{ minWidth: 200, ...style }}
        // showNow={true}
        inputReadOnly={readOnly}
        allowClear={(!readOnly && allowClear) || false}
        className="my-date-picker"
        allowEmpty={[true, true]}
        placeholder={["From", "To"]}
        presets={rangePresets}
        // renderExtraFooter={() => (
        //   <Flex vertical gap={15}>
        //     <div
        //       onClick={handleThisMonth}
        //     >
        //       This Month
        //     </div>
        //     <div
        //       onClick={handleToday}
        //     >
        //       Today
        //     </div>
        //   </Flex>
        // )}
        // open={open}
        // onOpenChange={setOpen}
        onChange={handleOnChange}
      />
    </Form.Item>
  );
};

export default InputFormRangeDatePicker;
