import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import DashboardSVG from "../../icons/DashboardSVG";
import MyLink from "../../components/myLink/MyLink";
import { useSelector } from "react-redux";
import ApiStorage from "../../redux/ApiStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyBill,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import "./SideBarMenu.scss";

const SideBarMenu = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const [selectedMenuKey, setselectedMenuKey] = useState(location.pathname);

  const lang = useSelector((state) => {
    return state.lang;
  });

  useEffect(() => {
    setselectedMenuKey(location.pathname);
  }, [location.pathname]);

  let svgSize = 26;

  const items = [
    {
      key: `/${lang}/dashboard`,
      className: "side-menu-item",
      label: (
        <MyLink className="menu-item-link-text" path="/dashboard" state={{}}>
          {t("WORD_DASHBOARD")}
        </MyLink>
      ),
      icon: <DashboardSVG size={svgSize} />,
    },
    {
      type: "group",
    },
    {
      key: `/${lang}/client/list`,
      className: "side-menu-item",
      label: (
        <MyLink
          className="menu-item-link-text"
          path="/client/list"
          state={{}}
          onClick={() => {
            ApiStorage.setClientPageFilters({});
          }}
        >
          {t("WORD_CLIENTS")}
        </MyLink>
      ),
      icon: (
        <FontAwesomeIcon icon={faUser} color="#fff" style={{ fontSize: 18 }} />
      ),
    },
    {
      key: `/${lang}/invoice/list`,
      className: "side-menu-item",
      label: (
        <MyLink
          className="menu-item-link-text"
          path="/invoice/list"
          state={{}}
          onClick={() => {
            ApiStorage.setInvoicePageFilters({});
          }}
        >
          {t("WORD_INVOICE")}
        </MyLink>
      ),
      icon: (
        <FontAwesomeIcon
          icon={faMoneyBill}
          color="#fff"
          style={{ fontSize: 18 }}
        />
      ),
    },
    // {
    //   key: `/${lang}/affiliate/list`,
    //   className: "side-menu-item",
    //   label: (
    //     <MyLink
    //       className="menu-item-link-text"
    //       path="/affiliate/list"
    //       state={{}}
    //     >
    //       {t("WORD_AFFILIATE")}
    //     </MyLink>
    //   ),
    //   icon: (
    //     <FontAwesomeIcon
    //       icon={faArrowRightArrowLeft}
    //       color="#fff"
    //       style={{ fontSize: 18 }}
    //     />
    //   ),
    // },
    // {
    //   key: `/${lang}/announcement/list`,
    //   className: "side-menu-item",
    //   label: (
    //     <MyLink
    //       className="menu-item-link-text"
    //       path="/announcement/list"
    //       state={{}}
    //     >
    //       {t("WORD_ANNOUNCEMENT")}
    //     </MyLink>
    //   ),
    //   icon: (
    //     <FontAwesomeIcon
    //       icon={faAlignJustify}
    //       color="#fff"
    //       style={{ fontSize: 18 }}
    //     />
    //   ),
    // },
  ];

  return (
    <Menu
      className="main-menu"
      defaultSelectedKeys={["1"]}
      items={items}
      selectedKeys={[selectedMenuKey]}
      onSelect={(menu) => {
        //  isMobile && onCloseToggle && onCloseToggle();
        setselectedMenuKey(menu?.key);
      }}
    />
  );
};

export default SideBarMenu;
