import { fetchFromUrlPOSTAsync } from "../utils/UrlHelper";

const URL_DASHBOARD_STATS = "/admin/dashboard/stats";

const fs = {
  getStats: async () => {
    const result = await fetchFromUrlPOSTAsync(URL_DASHBOARD_STATS, []);
    return result;
  },
};

const loc = {
};

const DashboardActions = Object.assign(fs, loc);

export default DashboardActions;
