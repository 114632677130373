import { Flex } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import StatisticCardBasic from "../../components/statisticCard/StatisticCardBasic";
import DashboardActions from "../../actions/DashboardActions";
import UtilNotify from "../../utils/UtilNotify";

const DashboardStatisticSection = () => {
  const { t } = useTranslation();

  const [dataStats, setdataStats] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getStat = async () => {
      setLoading(true);
      const result = await DashboardActions.getStats();
      if (result.success) {
        setdataStats(result.data);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setLoading(false);
    };
    getStat();
  }, []);

  return (
    <Flex gap={10} wrap>
      <StatisticCardBasic
        title={t("WORD_CONVERSION_RATE")}
        loading={loading}
        amount={dataStats?.conversionRate}
      />

      <StatisticCardBasic
        title={t("WORD_REGISTERED")}
        loading={loading}
        amount={dataStats?.registerCount}
      />
      <StatisticCardBasic
        title={t("WORD_ACTIVE")}
        loading={loading}
        amount={dataStats?.activeCount}
      />
      <StatisticCardBasic
        title={t("WORD_TRIAL")}
        loading={loading}
        amount={dataStats?.trialCount}
      />
      <StatisticCardBasic
        title={t("WORD_NOT_CONFIRMED")}
        loading={loading}
        amount={dataStats?.notConfirmedCount}
      />
      <StatisticCardBasic
        title={t("WORD_BLOCKED")}
        loading={loading}
        amount={dataStats?.blockedCount}
      />

      {dataStats?.packageList?.map((oo) => {
        return (
          <StatisticCardBasic
            key={oo.idService}
            title={oo.serviceName}
            amount={oo.count}
          />
        );
      })}

      <StatisticCardBasic
        title={t("WORD_AVERAGE_PRICE")}
        loading={loading}
        amount={dataStats?.averagePrice}
      />
    </Flex>
  );
};

export default DashboardStatisticSection;
