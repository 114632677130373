import React, { useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const MyCaptcha = ({ onChange }) => {
  const reCaptchaRef = useRef();

  useEffect(() => {
    let isSubscribed = true;
    const ref = reCaptchaRef?.current;
    const check = async () => {
      const token = await ref?.executeAsync();
      if (isSubscribed) {
        if (token) {
          onChange && onChange(token);
        }
      }
      // reCaptchaRef.current.reset();
    };
    check();
    return () => {
      // ref?.reset();
      isSubscribed = false;
    };
  }, [reCaptchaRef, onChange]);

  return (
    <ReCAPTCHA
      ref={reCaptchaRef}
      className="my-chapcha"
      sitekey={process.env.REACT_APP_CHAPCHA_KEY}
      // onChange={onChange}
      size="invisible"
    />
  );
};

export default MyCaptcha;
