import React, { useEffect, useMemo, useRef, useState } from "react";
import MySpaceVertical from "../../../components/mySpace/MySpaceVertical";
import MyPageHeader from "../../../components/myPageHeader/MyPageHeader";
import MyTable from "../../../components/myTable/MyTable";
import { useTranslation } from "react-i18next";
import MySpace from "../../../components/mySpace/MySpace";
import MyTooltip from "../../../components/myTooltip/MyTooltip";
import MyButtonTableAction from "../../../components/myButton/MyButtonTableAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import InvoiceCreateModal from "../invoiceCreate/InvoiceCreateModal";
import InvoiceViewModal from "../invoiceView/InvoiceViewModal";
import InvoiceActions from "../../../actions/InvoiceActions";
import UtilNumber from "../../../utils/UtilNumber";
import FormApp from "../../../components/formApp/FormApp";
import { Flex, Tag } from "antd";
import InputNumberFormItem from "../../../components/myInputForm/InputNumberFormItem";
import MyButtonText from "../../../components/myButton/MyButtonText";
import { useForm } from "antd/es/form/Form";
import UtilNotify from "../../../utils/UtilNotify";
import { useSelector } from "react-redux";
import UtilDate from "../../../utils/UtilDate";
import SelectInvoiceType from "../../../components/selects/SelectInvoiceType";
import SelectInvoiceStatus from "../../../components/selects/SelectInvoiceStatus";
import ApiStorage from "../../../redux/ApiStorage";
import SelectInvoiceMethod from "../../../components/selects/SelectInvoiceMethod";
import InputFormRangeDatePicker from "../../../components/inputFormRangeDatePicker/InputFormRangeDatePicker";
import SelectInvoiceDateRangeSelector from "../../../components/selects/SelectInvoiceDateRangeSelector";
import MyButtonSuccess from "../../../components/myButton/MyButtonSuccess";

const formName = "formInvoiceFilter";

const InvoiceList = () => {
  const { t } = useTranslation();
  const refModal = useRef();
  const refModalView = useRef();
  const [form] = useForm();

  const [totalRows, settotalRows] = useState(0);
  const [search] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setpagination] = useState({ page: 0, perPage: 50 });
  const [sort, setsort] = useState("");
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const invoicePageFilters = useSelector((state) => {
    return state.invoicePageFilters;
  });

  useEffect(() => {
    const fill = async () => {
      setLoading(true);
      const fetchParams = {
        page: pagination?.page,
        perPage: pagination?.perPage,
        sort: sort,
        q: "",
        filter: { ...invoicePageFilters },
        search: "",
      };
      const result = await InvoiceActions.fillPagination(fetchParams);
      if (result.success) {
        setData(result.data);
        settotalRows(result.totalRows);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setLoading(false);
    };
    fill();
  }, [search, sort, pagination, invoicePageFilters]);

  const onChangeTable = (_, __, sorter) => {
    let so = "";
    if (sorter.order) {
      so = `${sorter.columnKey} ${sorter.order === "ascend" ? "ASC" : "DESC"}`;
    }
    setsort(so);
  };

  // const onSelectChange = (newSelectedRowKeys, selectedRows) => {
  //   setSelectedRowKeys(newSelectedRowKeys);
  // };

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };

  // const hasSelected = selectedRowKeys.length > 0;

  const onFinish = (values) => {
    setpagination((ov) => {
      return { page: 0, perPage: ov.perPage };
    });
    if (values.date) {
      values.dateFrom = values.date[0];
      values.dateTo = values.date[1];
    }
    ApiStorage.setInvoicePageFilters(values);
  };

  // const handleRowClick = (record, e) => {
  //   if (e.target.closest(".ant-popconfirm") || e.target.closest("button")) {
  //     return;
  //   }
  //   const selectedRowKey = record.idInvoice;
  //   const newSelectedRowKeys = selectedRowKeys.includes(selectedRowKey)
  //     ? selectedRowKeys.filter((key) => key !== selectedRowKey)
  //     : [...selectedRowKeys, selectedRowKey];

  //   onSelectChange(newSelectedRowKeys, []);
  // };

  const columns = useMemo(() => {
    let col = [
      {
        title: t("WORD_ID"),
        dataIndex: "idInvoice",
        key: "idInvoice",
        sorter: true,
        width: 50,
      },
      {
        title: t("WORD_COMPANY_NAME"),
        dataIndex: "companyName",
        key: "companyName",
        sorter: true,
        width: 250,
      },
      {
        title: t("WORD_CREATED_DATE"),
        dataIndex: "createDate",
        key: "createDate",
        sorter: true,
        align: "center",
        width: 120,
        render: (value) => {
          return <div>{UtilDate.formatDateTime(value)}</div>;
        },
      },
      {
        title: t("WORD_TOTAL_AMOUNT"),
        dataIndex: "totalAmount",
        key: "totalAmount",
        width: 120,
        sorter: true,
        align: "right",
        render(value, { currencyCode }) {
          return (
            <>
              <div>
                {UtilNumber.formatDouble(value)} {currencyCode}
              </div>
            </>
          );
        },
      },
      {
        title: t("WORD_STATUS"),
        dataIndex: "status",
        key: "status",
        sorter: true,
        align: "center",
        width: 100,
        render: (value) => {
          const status = InvoiceActions.getStatus(value);
          return (
            <Tag
              style={{ minWidth: 100, textAlign: "center", borderRadius: 20 }}
              color={status.color}
            >
              {status.label}
            </Tag>
          );
        },
      },
      {
        title: t("WORD_TYPE"),
        dataIndex: "invoiceType",
        sorter: true,
        key: "invoiceType",
        align: "center",
        width: 100,
        render: (value) => {
          const status = InvoiceActions.getType(value);
          return (
            <div
              style={{ minWidth: 100, textAlign: "center" }}
              // color={status.color}
            >
              {status.label}
            </div>
          );
        },
      },
      {
        title: t("WORD_METHOD"),
        dataIndex: "paymentMethod",
        key: "paymentMethod",
        align: "center",
        sorter: true,
        width: 80,
        render: (value) => {
          const status = InvoiceActions.getMethod(value);
          return (
            <div
              style={{ minWidth: 100, textAlign: "center" }}
              // color={status.color}
            >
              {status.label}
            </div>
          );
        },
      },
      {
        title: t("WORD_PERIOD"),
        dataIndex: "invoiceDateFrom",
        key: "invoiceDateFrom",
        align: "center",
        width: 100,
        render: (value, { invoiceDateTo }) => {
          return (
            <div>
              {UtilDate.formatDate(value)}-{UtilDate.formatDate(invoiceDateTo)}
            </div>
          );
        },
      },
      {
        title: t("WORD_ACTIONS"),
        dataIndex: "",
        key: "x",
        width: 60,
        render: (_, { idInvoice, status }) => (
          <MySpace>
            <MyTooltip title={t("WORD_VIEW")}>
              <MyButtonTableAction
                onClick={() => {
                  refModalView?.current?.viewInvoice(idInvoice);
                }}
                icon={<FontAwesomeIcon icon={faEye} color="#fff" size="xs" />}
              />
            </MyTooltip>
          </MySpace>
        ),
      },
    ];
    return col;
  }, [t]);

  const handleChangeFilter = () => {
    form.submit();
  };

  return (
    <>
      <MySpaceVertical fullWidth size={"middle"}>
        <MyPageHeader
          title={t("WORD_INVOICE")}
          inPage
          extraLeft={
            <MySpace>
              <FormApp
                onFinish={onFinish}
                name={formName}
                form={form}
                className="filter-form"
                initialValues={{ dateType: 1 }}
              >
                <Flex gap={5} align="center" justify="center">
                  <SelectInvoiceDateRangeSelector name="dateType" />
                  <InputFormRangeDatePicker
                    name="date"
                    placeholder={t("WORD_DATE")}
                    onChange={handleChangeFilter}
                  />
                  <InputNumberFormItem
                    style={{ minWidth: 110 }}
                    name="totalAmount"
                    placeholder={t("WORD_TOTAL_AMOUNT")}
                    // onClear={handleChangeFilter}
                    onPressEnter={handleChangeFilter}
                  />
                  <SelectInvoiceStatus
                    name="status"
                    placeholder={t("WORD_SELECT_STATUS")}
                    width={150}
                    onChange={handleChangeFilter}
                  />
                  <SelectInvoiceType
                    name="invoiceType"
                    placeholder={t("WORD_SELECT_TYPE")}
                    width={150}
                    onChange={handleChangeFilter}
                  />
                  <SelectInvoiceMethod
                    name="paymentMethod"
                    placeholder={t("WORD_SELECT_METHOD")}
                    width={150}
                    onChange={handleChangeFilter}
                  />
                  <MyButtonText
                    label={t("WORD_RESET_FILTERS")}
                    onClick={() => {
                      ApiStorage.setInvoicePageFilters({});
                      form.resetFields();
                    }}
                  />
                </Flex>
              </FormApp>
              {/* <div>
                {hasSelected
                  ? `${t("WORD_SELECTED")} ${selectedRowKeys.length} ${selectedRowKeys.length > 1
                    ? t("WORD_ITEMS")
                    : t("WORD_ITEM")
                  }`
                  : ""}
              </div> */}
            </MySpace>
          }
          // extraRight={
          //   <MySpace>
          //     <MyButtonSuccess
          //       label={t("WORD_ADD_INVOICE")}
          //       withIcon
          //       onClick={() => {
          //         refModal?.current?.view();
          //       }}
          //     />
          //   </MySpace>
          // }
        />
        <MyTable
          rowKey="idInvoice"
          columns={columns}
          loading={loading}
          dataSource={data}
          total={totalRows}
          page={pagination?.page}
          pageSize={pagination?.perPage}
          // rowSelection={rowSelection}
          onChange={onChangeTable}
          scroll={{ x: 1000 }}
          // onRow={(record) => ({
          //   onClick: (e) => handleRowClick(record, e),
          // })}
          onChangePage={(e, ee) => {
            setpagination({ page: e, perPage: ee });
          }}
        />
      </MySpaceVertical>
      <InvoiceCreateModal
        ref={refModal}
        extraBtn={<MyButtonSuccess label={t("WORD_SAVE_AND_SEND")} />}
      />
      <InvoiceViewModal ref={refModalView} />
    </>
  );
};

export default InvoiceList;
