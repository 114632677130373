import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelectFormItem from "../mySelectFormItem/MySelectFormItem";

const SelectInvoiceDateRangeSelector = ({
  style,
  ...otherProps
}) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    let data = [
      { label: "Created", value: 1 },
      { label: "Paid", value: 2 },
      { label: "Period", value: 3 },
    ];
    setlist(data);
  }, [t]);

  return (
    <MySelectFormItem
      {...otherProps}
      style={{ minWidth: 100, ...style }}
      options={list}
    />
  );
};

export default SelectInvoiceDateRangeSelector;
