import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import OutsideLayout from "../layouts/outsideLayout/OutsideLayout";
import Dashboard from "../pages/dashboard/Dashboard";
import MainLayout from "../layouts/mainLayout/MainLayout";
import ClientList from "../pages/clients/clientList/ClientList";
import InvoiceList from "../pages/invoice/invoiceList/InvoiceList";
import LoginPage from "../pages/users/login/LoginPage";
import ClientSettingsLayout from "../layouts/clientSettingsLayout/ClientSettingsLayout";
import ClientEdit from "../pages/clients/clientEdit/ClientEdit";
import ClientDevices from "../pages/clients/clientDevices/ClientDevices";
import ClientUsers from "../pages/clients/clientUsers/ClientUsers";
import ClientBilling from "../pages/clients/clientBilling/ClientBilling";
import ClientLogs from "../pages/clients/clientLogs/ClientLogs";
import ClientView from "../pages/clients/clientView/ClientView";
import Setup2FaPage from "../pages/settings/setup2faPage/Setup2FaPage";
import AdminSettingsPage from "../pages/settings/AdminSettingsPage";
import AppRoutePrivate from "./AppRoutePrivate";
import Verify2FaPage from "../pages/settings/verify2faPage/Verify2FaPage";

const AppRoute = () => {
  return (
    <Routes>
      <Route path="/:lang">
        <Route index element={<Navigate to="login" replace />} />
        <Route
          path="login"
          element={
            <OutsideLayout>
              <LoginPage />
            </OutsideLayout>
          }
        />
        <Route
          path="dashboard"
          element={
            <AppRoutePrivate>
              <MainLayout fullPage>
                <Dashboard />
              </MainLayout>
            </AppRoutePrivate>
          }
        />
        <Route path="client">
          <Route index element={<Navigate to="list" replace />} />
          <Route
            path="list"
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <ClientList />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="view/:_id"
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <ClientSettingsLayout fullPage>
                    <ClientView />
                  </ClientSettingsLayout>
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="edit/:_id"
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <ClientSettingsLayout>
                    <ClientEdit />
                  </ClientSettingsLayout>
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="devices/:_id"
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <ClientSettingsLayout fullPage>
                    <ClientDevices />
                  </ClientSettingsLayout>
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="users/:_id"
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <ClientSettingsLayout fullPage>
                    <ClientUsers />
                  </ClientSettingsLayout>
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="billing/:_id"
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <ClientSettingsLayout fullPage>
                    <ClientBilling />
                  </ClientSettingsLayout>
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="logs/:_id"
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <ClientSettingsLayout fullPage>
                    <ClientLogs />
                  </ClientSettingsLayout>
                </MainLayout>
              </AppRoutePrivate>
            }
          />
        </Route>
        <Route path="invoice">
          <Route index element={<Navigate to="list" replace />} />
          <Route
            path="list"
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <InvoiceList />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
        </Route>
        {/* <Route path="affiliate">
          <Route index element={<Navigate to="list" replace />} />
          <Route
            path="list"
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <AffiliateList />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
        </Route> */}
        {/* <Route path="announcement">
          <Route index element={<Navigate to="list" replace />} />
          <Route
            path="list"
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <AnnouncementList />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="edit"
            element={
              <AppRoutePrivate>
                <MainLayout>
                  <AnnouncementEdit />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="create"
            element={
              <AppRoutePrivate>
                <MainLayout>
                  <AnnouncementEdit isNew />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
        </Route> */}
        <Route path="admins">
          <Route
            index
            path="account_settings"
            replace
            element={
              <AppRoutePrivate>
                <MainLayout>
                  <AdminSettingsPage />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="set_up_2_step"
            element={
              <AppRoutePrivate>
                <MainLayout>
                  <Setup2FaPage />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="turn_off_2_step"
            element={
              <AppRoutePrivate>
                <MainLayout>
                  <Verify2FaPage isTurnOff />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
        </Route>
      </Route>
      <Route path="/" index element={<Navigate to={"/en/login"} replace />} />
      <Route path="*" element={<Navigate to={"/en/login"} />} />
    </Routes>
  );
};

export default AppRoute;
