import React from "react";
import { CopyOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { message } from "antd";
import colors from "../../Color.scss";

const ButtonCopy = ({ className, value, disabled }) => {
  return disabled ? (
    <CopyOutlined
      className={className}
      style={{ color: colors.primaryColorLighter }}
      disabled={true}
    />
  ) : (
    <CopyToClipboard
      text={value}
      onCopy={() => {
        message.info(`Copied ${value}`);
      }}
    >
      <CopyOutlined
        className={className}
        style={{ color: colors.primaryColorLighter }}
      />
    </CopyToClipboard>
  );
};

export default ButtonCopy;
