import ApiLocalStorage from "./ApiLocalStorage";
import { store } from "./store";

const ApiStorage = {
  setLanguage: (lang) => {
    store.dispatch({ type: "SET_LANG", payload: lang });
  },
  setSelectedTheme: (theme) => {
    store.dispatch({ type: "SET_SELECTED_THEME", payload: theme });
  },
  getSelectedTheme: () => {
    const { selectedTheme } = store.getState();
    return selectedTheme;
  },
  getDeviceKey: () => {
    const { deviceKey } = store.getState();
    return deviceKey;
  },
  setDeviceKey: (deviceKey) => {
    store.dispatch({ type: "SET_DEVICEKEY", payload: deviceKey });
  },
  setAccessToken: (accessToken) => {
    store.dispatch({ type: "SET_ACCESS_TOKEN", payload: accessToken });
  },
  getAccessToken: () => {
    const { accessToken } = store.getState();
    return accessToken;
  },
  setCurrentUser: (user) => {
    store.dispatch({ type: "SET_CURRENT_USER", payload: user });
    if (user?.hasOwnProperty("sessionToken")) {
      ApiStorage.setAccessToken(user.sessionToken);
    }
    if (user?.hasOwnProperty("lang")) {
      ApiStorage.setLanguage(user.lang);
    }
  },
  getCurrentUser: () => {
    const { currentUser } = store.getState();
    return currentUser;
  },
  clearUser: () => {
    ApiStorage.setCurrentUser({});
    ApiLocalStorage.removeItem(ApiLocalStorage.TEMP_REMEMBER_ME);
  },
  setClientPageFilters: (filter) => {
    store.dispatch({
      type: "SET_CLIENT_PAGE_FILTERS",
      payload: filter,
    });
  },
  setInvoicePageFilters: (filter) => {
    store.dispatch({
      type: "SET_INVOICE_PAGE_FILTERS",
      payload: filter,
    });
  },
};

export default ApiStorage;
