import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelectFormItem from "../mySelectFormItem/MySelectFormItem";

const SelectInvoiceMethod = ({ ...otherProps }) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    let data = [
      // { label: "Invoice", value: 1 },
      { label: "Credit Card", value: 2 },
      // { label: "Paypal", value: 3 },
      { label: "Arca", value: 4 },
      { label: "Bank Transfer", value: 5 },
      { label: "IDRAM", value: 6 },
    ];
    setlist(data);
  }, [t]);

  return <MySelectFormItem {...otherProps} options={list} allowClear={true} />;
};

export default SelectInvoiceMethod;
