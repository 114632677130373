import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import { QRCodeSVG } from "qrcode.react";
import { useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import AdminActions from "../../../actions/AdminActions";
import UtilNotify from "../../../utils/UtilNotify";
import MyPageHeader from "../../../components/myPageHeader/MyPageHeader";
import MySpace from "../../../components/mySpace/MySpace";
import MyText from "../../../components/myText/MyText";
import ApiStorage from "../../../redux/ApiStorage";
import FormApp from "../../../components/formApp/FormApp";
import InputTextFormItem from "../../../components/myInputForm/InputTextFormItem";
import useNavigateApi from "../../../appRoute/useNavigateApi";
import MyButtonMain from "../../../components/myButton/MyButtonMain";
import MyCard from "../../../components/myCard/MyCard";
import MySpaceVertical from "../../../components/mySpace/MySpaceVertical";
import ButtonCopy from "../../../components/buttonCopy/ButtonCopy";
import colors from "../../../Color.scss";
import MyDivider from "../../../components/myDivider/MyDivider";

const formName = `set-up-2fa`;

const Setup2FaPage = () => {
  const { t } = useTranslation();
  const navigateApi = useNavigateApi();

  const is2FAenabled = useSelector((state) => {
    return state.currentUser?.is2FAenabled;
  });

  const [saving, setsaving] = useState(false);
  const [loading, setloading] = useState(true);

  const [form] = useForm();

  const secret2Fa = Form.useWatch("secret2Fa", form);
  const aut = Form.useWatch("aut", form);

  useEffect(() => {
    if (is2FAenabled) {
      navigateApi(`/admins/account_settings`);
    }
  }, [navigateApi, is2FAenabled]);

  useEffect(() => {
    const get = async () => {
      setloading(true);
      const result = await AdminActions.signUp2Fa();
      if (result.success) {
        form.setFieldsValue(result.data);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
        navigateApi(-1);
      }
      setTimeout(() => {
        setloading(false);
      }, 500);
    };
    get();
  }, [navigateApi, form]);

  const onFinish = async (values) => {
    setsaving(true);
    let result = await AdminActions.saveUp2Fa(values);
    if (result.success) {
      UtilNotify.notifySuccess("WORD_SUCCESS");
      result.data.verify2fasuccess = true;
      ApiStorage.setCurrentUser(result.data);
      navigateApi(-1);
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    form.setFieldsValue({ digitCode: "" });
    setsaving(false);
  };

  return (
    <MyCard loading={loading} fullHeight>
      <MyPageHeader
        title="Setup two-step verification"
        onBack={() => {
          navigateApi(-1);
        }}
      />
      <MySpace
        block
        style={{ marginTop: 20 }}
        size={"middle"}
        direction="vertical"
      >
        <MyText>
          {t("WORD_TWO_STEP_VERIFICATION_SUBTITLE")}
          <a
            style={{ margin: 4, color: colors.primaryColorLighter }}
            target="_blanck"
            href="https://ordersbook.com/"
          >
            {t("WORD_SET_IT_UP_TOGETHER")}
          </a>
          . {t("WORD_GRAB_YOUR_MOBILE_PHONE_AND_LETS_BEGIN")}
        </MyText>
        <MyDivider smaller />
        <MySpace direction="vertical">
          <MySpace>
            <MyText className="number-bg">1</MyText>
            <MyText bold>{t("WORD_INSTALL_A_VERIFICATION_APP")}</MyText>
          </MySpace>
          <MySpace>
            <MyText>
              {t("WORD_FA_STEP_SUBTITLE_1")}
              <a
                style={{ margin: 4, color: colors.primaryColorLighter }}
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
              >
                {t("WORD_GOOGLE_AUTHENTICATOR")}
              </a>
              , {t("WORD_FA_STEP_SUBTITLE_2")}
            </MyText>
          </MySpace>
        </MySpace>
        <MyDivider smaller />
        <MySpace direction="vertical">
          <MySpace>
            <MyText className="number-bg">2</MyText>
            <MyText bold>{t("WORD_SCAN_THE_ORDERSBOOK_QR_CODE")}</MyText>
          </MySpace>
          <MySpace>
            <MyText>{t("WORD_FA_STEP_SUBTITLE_3")}</MyText>
          </MySpace>
          <MySpaceVertical
            align="center"
            style={{ justifyContent: "center" }}
            fullWidth
          >
            <MyText>{t("WORD_SCAN_WITH_YOUR_VERIFICATION_APP")}</MyText>
            <QRCodeSVG value={aut} />
            <MyText>{t("WORD_CANT_SCAN_IT?_ENTER_THE_KEY_MANUALLY")}</MyText>
            <MySpace className="copy-row-wrapper">
              <MyText color={colors.primaryColorLighter} strong>
                {secret2Fa}
              </MyText>
              <ButtonCopy className="btn-copy" value={secret2Fa} />
            </MySpace>
          </MySpaceVertical>
          <MyDivider />
        </MySpace>
        <MySpace direction="vertical" block>
          <MySpace block>
            <MyText className="number-bg">3</MyText>
            <MyText bold>{t("WORD_ENTER_THE_VERIFICATION_CODE")}</MyText>
          </MySpace>
          <MySpace>
            <MyText>{t("WORD_FA_STEP_SUBTITLE_4")}</MyText>
          </MySpace>
          <FormApp form={form} onFinish={onFinish} name={formName}>
            <InputTextFormItem name="secret2Fa" hidden />
            <InputTextFormItem name="aut" hidden />
            <MySpace direction="vertical" block fullWidth>
              <InputTextFormItem
                name="digitCode"
                label={t("WORD_DIGIT_CODE")}
                placeholder=" "
                style={{ width: "100%" }}
                required
              />
              <MyDivider />
              <MyButtonMain
                form={formName}
                label={t("WORD_SAVE")}
                block
                loading={saving}
              />
            </MySpace>
          </FormApp>
        </MySpace>
      </MySpace>
    </MyCard>
  );
};

export default Setup2FaPage;
