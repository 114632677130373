import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelectFormMultiItem from "../mySelectFormMultiItem/MySelectFormMultiItem";

const SelectRoleMulti = ({ allowClear = true, ...otherProps }) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    const data = [
      { label: t("WORD_ADMIN"), value: "1" },
      { label: t("WORD_BRANCH"), value: "2" },
      { label: t("WORD_MANAGER"), value: "3" },
    ];
    setlist(data);
  }, [t]);

  return (
    <MySelectFormMultiItem
      {...otherProps}
      options={list}
      allowClear={allowClear}
      style={{ minWidth: 200 }}
    />
  );
};

export default SelectRoleMulti;
