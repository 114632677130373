import { Layout } from "antd";
import React from "react";
import useWindowSize from "../../utils/useWindowSize";
import MainLayoutSideMenu from "./MainLayoutSideMenu";

const { Content } = Layout;

const MainLayout = ({ children, fullPage }) => {
  const { isMobile } = useWindowSize();

  return (
    <Layout
      style={{
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
      }}
    >
      <MainLayoutSideMenu />
      <Content
        style={{
          width: fullPage || isMobile ? "100%" : 700,
          padding: 15,
          flex: !fullPage ? "none" : "auto",
          overflowY: "auto",
        }}
      >
        {children}
      </Content>
    </Layout>
  );
};

export default MainLayout;
