import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
} from "react";
import MyModalForm from "../../../components/myModal/MyModalForm";
import { Flex, Form } from "antd";
import FormApp from "../../../components/formApp/FormApp";
import InputFormRangeDatePicker from "../../../components/inputFormRangeDatePicker/InputFormRangeDatePicker";
import InputNumberFormItem from "../../../components/myInputForm/InputNumberFormItem";
import { useTranslation } from "react-i18next";
import { useForm } from "antd/es/form/Form";
import SelectPackage from "../../../components/selects/SelectPackage";
import SelectMonthCount from "../../../components/selects/SelectMonthCount";
import { ClientContext } from "../../../layouts/clientSettingsLayout/ClientSettingsLayout";
import UtilDate from "../../../utils/UtilDate";
import InvoiceActions from "../../../actions/InvoiceActions";
import UtilNotify from "../../../utils/UtilNotify";

const formName = "formBillingPaymentCreate";

const ClientBillingPaymentCreateModal = forwardRef(({ onChange }, ref) => {
  const refModal = useRef();
  const { t } = useTranslation();
  const [form] = useForm();
  const monthCount = Form.useWatch("monthCount", form);

  const { clientData } = useContext(ClientContext);

  useImperativeHandle(ref, () => ({
    view: () => {
      refModal.current.open();
      form.setFieldsValue({
        idService: clientData?.serviceType,
        monthCount: 1,
        date: [UtilDate.getThisMonthStart(), UtilDate.getThisMonthEnd()],
        totalAmount: clientData?.totalAmount,
      });
    },
  }));

  const onFinish = async (values) => {
    values.dateFrom = values?.date[0];
    values.dateTo = values?.date[1];
    values.idClient = clientData?.idClient;
    console.log("values :>> ", values);
    const result = await InvoiceActions.createQuick(values);
    if (result.success) {
      refModal.current.close();
      UtilNotify.notifySuccess(t("WORD_PAYMENT_REGISTERED_SUCCESSFULLY"));
      onChange && onChange();
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
  };

  return (
    <MyModalForm
      ref={refModal}
      title={t("WORD_CREATE_PAYMENT")}
      labelOk={t("WORD_CREATE")}
      success
      formName={formName}
      width={500}
    >
      <FormApp onFinish={onFinish} name={formName} form={form}>
        <Flex gap={10} vertical>
          <InputFormRangeDatePicker
            name="date"
            label={t("WORD_DATE")}
            required
          />
          <SelectPackage
            name={"idService"}
            label={t("WORD_PACKAGE")}
            required
            allowClear={false}
            onChangeItem={(oo) => {
              let totalAmount = 0;
              let price = oo.priceMontly;
              if (monthCount === 1) {
                totalAmount = oo.priceMontly;
              } else if (monthCount === 12) {
                totalAmount = oo.priceYearly;
                price = oo.priceYearly;
              } else {
                totalAmount = oo.priceMontly * monthCount;
              }
              form.setFieldsValue({ totalAmount, price });
            }}
          />
          <SelectMonthCount
            name={"monthCount"}
            label={t("WORD_MONTH_COUNT")}
            required
            onChange={(e) => {
              form.setFieldsValue({ monthCount: e || "" });
            }}
          />
          {/* <MySpace>
            <InputNumberFormItem
              name={"monthCount"}
              label={t("WORD_MONTH_COUNT")}
              required
              hidden={monthCountSelect}
              onChange={(e) => {
                if (e) {
                  const price = form.getFieldValue('price');
                  form.setFieldsValue({ totalAmount: price * e });
                }
              }}
            />
          </MySpace> */}
          {/* <InputNumberFormItem
            name="price"
            label={t("WORD_PRICE")}
            hidden
          /> */}
          <InputNumberFormItem
            name={"totalAmount"}
            label={t("WORD_TOTAL")}
            placeholder={t("WORD_TOTAL")}
            required
          />
        </Flex>
      </FormApp>
    </MyModalForm>
  );
});

export default ClientBillingPaymentCreateModal;
