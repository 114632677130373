import React, { useContext, useEffect, useMemo, useState } from "react";
import MySpaceVertical from "../../../components/mySpace/MySpaceVertical";
import { useTranslation } from "react-i18next";
import MyPageHeader from "../../../components/myPageHeader/MyPageHeader";
import MyTable from "../../../components/myTable/MyTable";
import UtilNotify from "../../../utils/UtilNotify";
import UtilDate from "../../../utils/UtilDate";
import DeviceActions from "../../../actions/DeviceActions";
import { ClientContext } from "../../../layouts/clientSettingsLayout/ClientSettingsLayout";
import SearchInput from "../../../components/myInputForm/SearchInput";

const ClientDevices = () => {
  const { t } = useTranslation();

  const { clientData } = useContext(ClientContext);

  const [totalRows, settotalRows] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setpagination] = useState({ page: 0, perPage: 50 });
  const [sort, setsort] = useState("");
  const [search, setSearch] = useState("");
  const [filter] = useState({});

  useEffect(() => {
    const fill = async () => {
      setLoading(true);
      const fetchParams = {
        page: pagination?.page,
        perPage: pagination?.perPage,
        sort: sort,
        q: "",
        filter: { ...filter, idClient: clientData?.idClient },
        search: search,
      };
      const result = await DeviceActions.fillPagination(fetchParams);
      if (result.success) {
        setData(result.data);
        settotalRows(result.totalRows);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setLoading(false);
    };
    fill();
  }, [pagination, sort, search, filter, clientData?.idClient]);

  const onChangeTable = (_, __, sorter) => {
    let so = "";
    if (sorter.order) {
      so = `${sorter.columnKey} ${sorter.order === "ascend" ? "ASC" : "DESC"}`;
    }
    setsort(so);
  };

  const setpage = (page) => {
    setpagination((ov) => ({ page: page, perPage: ov.perPage }));
  };

  const columns = useMemo(() => {
    let col = [
      {
        title: t("WORD_ID"),
        dataIndex: "idDevice",
        key: "idDevice",
        width: 50,
        sorter: true,
      },
      {
        title: t("WORD_SN"),
        dataIndex: "serialNumber",
        key: "serialNumber",
        width: 200,
        sorter: true,
      },
      {
        title: t("WORD_MAC_ADDRESS"),
        dataIndex: "macAddress",
        key: "macAddress",
        width: 200,
        sorter: true,
      },
      {
        title: t("WORD_NAME"),
        dataIndex: "deviceName",
        key: "deviceName",
        width: 200,
        sorter: true,
      },
      {
        title: t("WORD_STATUS"),
        dataIndex: "status",
        key: "status",
        width: 100,
        sorter: true,
        render: (value) => {
          const status = DeviceActions.getStatusLabel(value);
          return (
            <div
              className="status-wrapper"
              style={{ backgroundColor: status.color }}
            >
              {t(status.label)}
            </div>
          );
        },
      },
      {
        title: t("WORD_CREATED_DATE"),
        dataIndex: "createDate",
        key: "createDate",
        width: 100,
        sorter: true,
        render: (value) => {
          return <div>{UtilDate.formatDate(value)}</div>;
        },
      },
    ];
    return col;
  }, [t]);

  return (
    <>
      <MySpaceVertical fullWidth size={"middle"}>
        <MyPageHeader
          title={t("WORD_CLIENT_DEVICES")}
          inPage
          extraLeft={
            <SearchInput
              onClear={() => {
                setSearch("");
                setpage(0);
              }}
              onPressEnter={(value) => {
                setSearch(value);
                setpage(0);
              }}
            />
          }
        />
        <MyTable
          rowKey="idDevice"
          columns={columns}
          loading={loading}
          dataSource={data}
          total={totalRows}
          page={pagination?.page}
          pageSize={pagination?.perPage}
          onChange={onChangeTable}
          onChangePage={(e, ee) => {
            setpagination({ page: e, perPage: ee });
          }}
        />
      </MySpaceVertical>
    </>
  );
};

export default ClientDevices;
