import React from "react";
import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import UtilNumber from "../../utils/UtilNumber";
import "./MyInputForm.scss";

const InputPasswordFormItem = ({
  classNameInput,
  className = "",
  label,
  name,
  placeholder,
  required = true,
  prefix,
  hidden = false,
  readOnly,
  allowClear = true,
  min,
  max,
}) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={label}
      name={name}
      hidden={hidden}
      className={`app-input ${className || ""}`}
      validateTrigger="onSubmit"
      rules={
        hidden || readOnly
          ? []
          : [
              {
                required: required && !hidden,
                validator: (o, value) => {
                  if (hidden) {
                    return Promise.resolve();
                  }
                  if (required && !value?.trim()) {
                    return Promise.reject(
                      new Error(
                        `${label || placeholder} ${t("WORD_IS_REQUIRED")}`
                      )
                    );
                  }
                  if (max && value.length > max) {
                    return Promise.reject(
                      new Error(`${name} cannot exceed ${max} characters.`)
                    );
                  }
                  if (min && value.length < min) {
                    return Promise.reject(
                      new Error(`${name} min ${min} characters.`)
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]
      }
    >
      <Input.Password
        className={`my-input input-password ${classNameInput || ""}`}
        readOnly={readOnly}
        placeholder={placeholder || label}
        prefix={prefix}
        allowClear={allowClear}
        autoComplete="nope"
        id={UtilNumber.getNewUniqID()}
      />
    </Form.Item>
  );
};

export default InputPasswordFormItem;
