import React, { memo } from "react";
import MyText from "../myText/MyText";
import MySpace from "../mySpace/MySpace";
import MyTextSecondary from "../myText/MyTextSecondary";
import ArrowLeftSVG from "../../icons/ArrowLeftSVG";
import "./MyPageHeader.scss";

const MyPageHeader = ({
  title,
  extraLeft,
  extraRight,
  subTitle,
  onBack,
  style,
  className,
  inPage,
  isMobile,
}) => {
  return (
    <MySpace
      fullWidth={!isMobile}
      spaceBetween
      align="center"
      className={className || ""}
    >
      <MySpace>
        <MySpace style={{ minWidth: 100, ...style }} align="top">
          {onBack && (
            <MyText className="arrow-icon">
              <ArrowLeftSVG onClick={onBack} />
            </MyText>
          )}
          <MyTextSecondary
            className={`my-title ${inPage ? "page-header-main" : ""}`}
            fontWeight={500}
            size={18}
            style={{ textWrap: "nowrap" }}
          >
            {title}
          </MyTextSecondary>
        </MySpace>
        <MyText>{subTitle}</MyText>
        <div>{extraLeft}</div>
      </MySpace>
      <div>{extraRight}</div>
    </MySpace>
  );
};

export default memo(MyPageHeader);
