import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./translations/en.json";
import translationAR from "./translations/ar.json";
import translationHY from "./translations/hy.json";
import translationRU from "./translations/ru.json";
import ApiStorage from "./redux/ApiStorage";

const defLanguage = "hy";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      ar: {
        translation: translationAR,
      },
      hy: {
        translation: translationHY,
      },
      ru: {
        translation: translationRU,
      },
    },
    fallbackLng: defLanguage,
    supportedLngs: ["en", "ar", "hy", "ru"],
    debug: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    load: "unspecific",
    react: {
      useSuspense: true,
    },
    detection: {
      order: ["path", "htmlTag", "navigator", "localStorage"],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
    },
  });

i18n.on("languageChanged", async (lang) => {
  if (i18n.language === lang) return;
  const html = document.querySelector("html");
  html && html.setAttribute("lang", lang);
  ApiStorage.setLanguage(lang);
  const us = { ...ApiStorage.getCurrentUser(), language: lang, lang: lang };
  ApiStorage.setCurrentUser(us);
});

export default i18n;
