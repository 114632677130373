import React from "react";
import LoadingIconApp from "./LoadingIconApp";

const LoadingIconAppCenter = ({ style }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <LoadingIconApp />
    </div>
  );
};

export default LoadingIconAppCenter;
