import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelectFormItem from "../mySelectFormItem/MySelectFormItem";
import ClientActions from "../../actions/ClientActions";
import UtilNotify from "../../utils/UtilNotify";

const SelectClient = ({ ...otherProps }) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);
  const [search, setsearch] = useState('');
  const [loading, setloading] = useState([]);

  useEffect(() => {
    // const data = [
    //   { label: "Serly", value: 0 },
    //   { label: "test", value: 1 },
    //   { label: "teshfgokhj", value: 2 },
    // ];
    // setlist(data);
    const fill = async () => {
      setloading(true);
      const fetchParams = {
        page: 0,
        perPage: 50,
        sort: '',
        q: "",
        filter: {},
        search: search,
      };
      const result = await ClientActions.fillPagination(fetchParams);
      if (result.success) {
        setlist(result.data.map((oo) => {
          return {
            ...oo,
            label: oo.companyName,
            value: oo.idClient
          }
        }));
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setloading(false);
    };
    fill();
  }, [t, search]);

  return <MySelectFormItem {...otherProps} options={list} loading={loading} showSearch={true} onSearch={setsearch} />;
};

export default SelectClient;
